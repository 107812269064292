import serviceBannerImage from "../../../Assets/videos/aboutUS.mp4";
import PageTitle from '../../../Components/PageTitle/PageTitle';

export const pageData = [
  // * ERP
  {
    page: "erp",
    banner: {
      title: "Enterprise Resource Planning (ERP)",
      subtitle:
        "Transform your Core Business Processes with Strategic ERPNext Solutions",
      video: "erp",
      // "https://www.request-quote.pixfar.com/wp-content/uploads/2024/06/erp.mp4",
    },
    service: [
      {
        icon: "../../../Assets/Images/erpe/12.svg",
        title: "TRENDING BUSINESS",
        desc: "Allow Pixfar to assist in logistics so that you can focus on opening up more distribution channels. With packing slips, delivery trips, and many other features, Pixfar is a wholesale distribution software and much more. Be it sales, purchase, or people management, we have got it covered. Features like auto-reorder and stock aging reports help you make data driven decisions so your wholesale distribution runs effectively. Create and maintain various variants based on any type of parameter.",
        image: "https://i.ibb.co/yfddBBz/home02.png",
      },
      {
        icon: "../../../Assets/Images/erpe/16.svg",
        title: "Human Resource (HR)",
        desc: "Recruit and manage employees and attendance, track leaves and expenses, and handle onboarding, training, and appraisals efficiently with Pixfar. It's human resource management made easy.",
        image: "https://i.ibb.co/1qBgcNY/home03.png",
      },
      {
        icon: "../../../Assets/Images/erpe/15 (1).svg",
        title: "Health Care",
        desc: "Healthcare systems around the world are in a race against time to secure medical equipment, allocate extra resources for intensive care, and expand their clinical workforce. In order to deliver the best outcome for patients, these clinicians require secure, real-time, and accurate data. Software for healthcare management is an important aspect of the overall ecosystem. While battling against the pandemic, the need for having an “All-In-One Healthcare Software” has been strongly identified. The healthcare module in ERPNext empowers any Healthcare facility to manage patients, appointments, consultations, laboratory, pharmacy, and much more. These institutions need a lot more than just a healthcare software to map all operations digitally. ",
        image: "https://i.ibb.co/1mPC5zw/home05.png",
      },
      {
        icon: "../../../Assets/Images/erpe/13 (1).svg",
        title: "Learning Management System",
        desc: "The rapid growth of the learning tech industry has increased ERPNext LMS software’s adaptability in educational institutes like schools, colleges, universities, learning centers, and coaching centers. From student management to admission dashboards, lesson plans to attendance records, fee collection to library management, and online examinations to data backup, ERPNext LMS solutions have provided a great pathway to streamline the entire teaching and learning process in an easy & hassle-free manner for students, educators, and administrators.",
        image: "https://i.ibb.co/qxPJzFB/mobile4.png",
      },
      {
        icon: "../../../Assets/Images/erpe/12 (1).svg",
        title: <h1>On Demand ERP</h1>,
        desc: "Welcome to Pixfar's On-Demand ERP Software Service - Unleash the Power of Integrated Business Management At Pixfar, we recognize the complexities of modern business operations and the need for a comprehensive solution to streamline and optimize processes. That's why we are proud to offer our On-Demand ERP (Enterprise Resource Planning) software service, designed to empower businesses with a unified platform for seamless management across departments and functions",
        image: "https://i.ibb.co/QYyR30G/data3.png",
      },
      {
        icon: "../../../Assets/Images/bigdata/13.svg",
        title: "Manufacturing",
        desc: "At Pixfar, we understand the unique challenges faced by manufacturers in today's competitive market. To help you streamline operations, improve efficiency, and drive growth, we offer our ERP for Manufacturing software service. Our solution is designed to provide comprehensive support for every aspect of your manufacturing process,",
        image: "https://i.ibb.co/PrRNWf9/erp6.jpg",
      },
    ],
    flow: "",
    tech: ["Angular", "React", "Vue.js", "Blazor (for .NET)"],
  },
  {
    page: "pos",
    banner: {
      title: "Point of sale (POS)",
      subtitle: "Streamline Your Sales Operations with Efficient POS Solutions",
      video:
        "https://www.request-quote.pixfar.com/wp-content/uploads/2024/06/pos.mp4",
    },
    test: (
      <PageTitle img={serviceBannerImage} title="Services" current="Services" />
    ),
    service: [
      {
        icon: "../../../Assets/Images/pos/posi1.svg",
        title: "User Management",
        desc: "Role-Based Access Control: Define roles (e.g., cashier, manager, admin) with specific permissions. User Profiles: Manage individual user accounts and profiles.",
        image: "https://i.ibb.co/2v784BG/home06.png",
      },
      {
        icon: "../../../Assets/Images/pos/posic07.svg",
        title: "Inventory Management",
        desc: "Keep track of your stock levels in real-time, manage reorders, and reduce the risk of overstocking or stockouts with our efficient inventory management features.",
        image: "https://i.ibb.co/L96B1dG/mobile3.png",
      },
      {
        icon: "../../../Assets/Images/pos/posi2.svg",
        title: "Sales Processing",
        desc: "Handle transactions swiftly and accurately, ensuring a smooth checkout experience for your customers. Our sales processing module supports multiple payment methods and provides detailed transaction records.",
        image: "https://i.ibb.co/crvTNP2/erp2.jpg",
      },
      {
        icon: "../../../Assets/Images/pos/posic10.svg",
        title: "Reporting and Analytics",
        desc: "Gain valuable insights into your sales performance with comprehensive reporting and analytics tools. Monitor sales trends, identify top-selling products, and make informed business decisions based on real-time data.",
        image: "https://i.ibb.co/PmG1Fy2/data5.png",
      },
      {
        icon: "../../../Assets/Images/pos/posic08.svg",
        title: "Security and Compliance",
        desc: "Ensure your business complies with industry standards and regulations. Our POS system includes robust security features to protect sensitive data and maintain customer trust.",
        image: "https://i.ibb.co/88tWHtq/home09.png",
      },
      {
        icon: "../../../Assets/Images/pos/posi5.svg",
        title: "Integration with Other Systems",
        desc: "Seamlessly integrate your POS system with other business management tools, including ERP, CRM, and inventory systems, to create a unified platform for all your operational needs.",
        image: "https://i.ibb.co/BGvy73d/home07.png",
      },
      {
        icon: "../../../Assets/Images/pos/posic09.png",
        title: "Support and Maintenance",
        desc: "Benefit from our dedicated support and maintenance services. Our team is here to help with any issues or updates, ensuring your POS system runs smoothly at all times.",
        image: "https://i.ibb.co/Htx0VH6/erp3.jpg",
      },
    ],
    flow: "",
    tech: [
      "React",
      "Angular",
      "Vue.js",
      "Node.js",
      " Django",
      "ASP.NET",
      "PostgreSQL",
      "MySQL",
      "MongoDB",
    ],
  },
  // * Big Data
  {
    page: "data-engineering",
    banner: {
      title: "Data Engineering",
      subtitle:
        "Data engineering is a crucial discipline within the field of data science that focuses on designing, constructing, and maintaining systems and architecture for collecting, storing, and analyzing large sets of data. The primary goal of data engineering is to ensure that data is accessible, reliable, and efficiently processed to support analytics and decision-making.",
      video: "data-engineering",
    },
    service: [
      {
        icon: "../../../Assets/Images/bigdata/13.svg",
        title: "EXTRACT TRANSFORM LOAD",
        desc: "Efficiently collect, transform, and load your data with Pixfar's ETL solutions. Our services streamline data integration processes, ensuring clean, reliable, and timely data for your business intelligence needs.",
        image: "https://i.ibb.co/1ryrL56/rdp1.png",
      },
      {
        icon: "../../../Assets/Images/bigdata/11.svg",
        title: "DATA WAREHOUSING",
        desc: "Pixfar provides robust data warehousing solutions to centralize your data, optimize storage, and enhance data retrieval. Our scalable architectures support your growing data needs, enabling seamless access to valuable insights.",
        image: "https://i.ibb.co/G92T9YS/rdp3.png",
      },
      {
        icon: "../../../Assets/Images/bigdata/12.svg",
        title: "DATA REPORTING",
        desc: "Unlock actionable insights with Pixfar's data reporting services. We create comprehensive, easy-to-understand reports that empower you to make informed decisions and track key performance metrics effectively.",
        image: "https://i.ibb.co/ZNgLWBq/web3.png",
      },
      {
        icon: "../../../Assets/Images/bigdata/14.svg",
        title: "FORECASTING",
        desc: "Anticipate future trends and make proactive decisions with Pixfar's forecasting solutions. Our advanced analytics and modeling techniques deliver accurate predictions, helping you plan and strategize with confidence.",
        image: "https://i.ibb.co/myNyZyp/data1.png",
      },
    ],
    flow: "",
    tech: [
      "React",
      "Angular",
      "Vue.js",
      "Node.js",
      " Django",
      "ASP.NET",
      "PostgreSQL",
      "MySQL",
      "MongoDB",
    ],
  },
  {
    page: "web-development",
    banner: {
      title: " Web Development",
      subtitle:
        "Elevate Your Online Presence with Cutting-Edge Web Development Services",
      video:
        "https://www.request-quote.pixfar.com/wp-content/uploads/2024/06/webdevelopment.mp4",
    },
    service: [
      {
        icon: "../../../Assets/Images/web/111.svg",
        title: "Back-End Development",
        desc: "Build robust and scalable server-side applications using technologies like Node.js, Python, and Ruby on Rails. Our back-end development services ensure your website functions smoothly and securely.",
        image: "https://i.ibb.co/0yb6X1L/mobile1.png",
      },
      {
        icon: "../../../Assets/Images/web/101.svg",
        title: "Full-Stack Development",
        desc: "Leverage the expertise of our full-stack developers who can handle both front-end and back-end tasks. This comprehensive approach ensures cohesive and efficient development processes.",
        image: "https://i.ibb.co/xXt41q5/erp4.jpg",
      },
      {
        icon: "../../../Assets/Images/web/108.svg",
        title: "E-Commerce Development",
        desc: "Develop powerful e-commerce platforms with features like product catalogs, shopping carts, and secure payment gateways. We create tailored e-commerce solutions to enhance your online sales and customer experience.",
        image: "https://i.ibb.co/T0Qv98r/home01.png",
      },
      {
        icon: "../../../Assets/Images/pos/ReportingandAnalytics.svg",
        title: "CMS Development",
        desc: "Implement user-friendly content management systems (CMS) like WordPress, Drupal, and Joomla. Our CMS development services enable you to easily manage and update your website content.",
        image: "https://i.ibb.co/Kbzt5NF/data2.png",
      },
      {
        icon: "../../../Assets/Images/web/106.svg",
        title: "UX/UI Design",
        desc: "Focus on creating intuitive and engaging user experiences with our UX/UI design services. We prioritize user-centric design to improve navigation, usability, and overall satisfaction.",
        image: "https://i.ibb.co/Hn0RxWh/web4.png",
      },
      {
        icon: "../../../Assets/Images/web/109.svg",
        title: "Website Maintenance and Support",
        desc: "Keep your website updated, secure, and running smoothly with our maintenance and support services. We provide regular updates, backups, and technical support to ensure optimal performance.",
        image: "https://i.ibb.co/yq166WV/mobile6.png",
      },
    ],
    flow: "",
    tech: [
      "React",
      "Angular",
      "Vue.js",
      "Node.js",
      " Django",
      "ASP.NET",
      "PostgreSQL",
      "MySQL",
      "MongoDB",
      "Express.js",
      "Linux",
      "Ruby on Rails",
      "MongoDB",
      "Apache",
      "PHP",
      "WordPress",
      "TypeScript",
      "GraphQL",
      "Docker",
    ],
  },

  {
    page: "mobile-apps",
    banner: {
      title: " Mobile Apps Development",
      subtitle:
        "Transform Your Business with Innovative Mobile App Development Solutions",
      video: "mobile-apps",
    },
    service: [
      {
        icon: "../../../Assets/Images/web/106.svg",
        title: "Android Applications",
        desc: "Unlock the potential of the Android platform with our expert app development services. From concept to deployment, we craft cutting-edge applications tailored to your business needs. Leverage the power of Android to reach and engage your audience on the world's most popular mobile platform.",
        image: "https://i.ibb.co/K6QyZdt/web6.png",
      },
      {
        icon: "../../../Assets/Images/web/108.svg",
        title: "IOS Applications",
        desc: "Experience the pinnacle of mobile innovation with Pixfar's iOS app development services. From concept to launch, we specialize in creating bespoke iOS applications that resonate with users and drive engagement. Harnessing the power of Apple's cutting-edge technologies, we bring your ideas to life on the world's most advanced mobile platform. Elevate your brand and captivate your audience with Pixfar's expert iOS app development.",
        image: "https://i.ibb.co/CHWYnwg/mobile2.png",
      },
    ],
    flow: "",
    tech: [
      "React Native",
      "Node.js",
      "Javascript",
      "Java",
      "PostgreSQL",
      "MySQL",
      "MongoDB",
      "Express.js",
      "Html",
    ],
  },
  // * Artificial Intelligence
  {
    page: "ai",
    banner: {
      title: "Artificial Intelligence",
      subtitle:
        "We leverage Generative AI and Machine Learning to streamline processes, improve efficiencies, and reduce costs with our AI models. Our NLP techniques enhance customer interactions and improve operational efficiency.",
      video: "ai",
      // "https://www.request-quote.pixfar.com/wp-content/uploads/2024/06/ai.mp4",
    },
    service: [
      {
        icon: "../../../Assets/Images/ai/106.svg",
        title: "AI Consulting",
        desc: "Navigate the future of AI with our consulting services. We identify use cases and create a roadmap for implementation via our AI-driven technologies.",
        image: "https://i.ibb.co/Qdyj01B/ai2.png",
      },
      {
        icon: "../../../Assets/Images/ai/107.svg",
        title: "Generative AI Acceleration",
        desc: "Lead the race for innovation with our generative AI services, from ideation to execution.",
        image: "https://i.ibb.co/tKrwfBh/ai4.png",
      },
      {
        icon: "../../../Assets/Images/ai/108.svg",
        title: "Predictive Modelling",
        desc: "Optimize future outcomes with our predictive modeling experience, utilizing machine learning to forecast trends and behaviors for strategic decision-making.",
        image: "https://i.ibb.co/L8ngVq8/ai3.png",
      },
      {
        icon: "../../../Assets/Images/ai/109.svg",
        title: "Image and Video Processing",
        desc: "Transform your visual data and documents into actionable insights. Our advanced image and video processing tools are powered by computer vision and deep learning.",
        image: "https://i.ibb.co/nzbRJDF/ai1.png",
      },
      {
        icon: "../../../Assets/Images/ai/110.svg",
        title: "Decision Systems",
        desc: "Enhance user engagement and personalization with our AI-powered decision-making systems.",
        image: "https://i.ibb.co/w6Y55XG/ai5.png",
      },
    ],
    flow: "../../../Assets/Images/ai/aiBanner.png",
    tech: [
      "TensorFlow",
      "PyTorch",
      "Scikit-learn",
      "Keras",
      "Apache Spark",
      "Microsoft Azure",
      "Amazon SageMaker",
      "IBM Watson",
    ],
  },
  // * Remote Desktop
  {
    page: "remote-desktop",
    banner: {
      title: "Remote Desktop Solution",
      subtitle:
        "A Remote Desktop Solution (RDS) is a technology that allows users to access and interact with a computer or server from a remote location as if they were physically present at the machine. This is accomplished through a network connection, typically over the internet, enabling remote control of the desktop environment, applications, and files.",
      video: "remote-desktop",
    },
    service: [
      {
        title: "Remote Desktop Hosting",
        desc: "Host your desktop environment on secure servers for easy remote access from any device.",
        image: "https://i.ibb.co/HxYRgMn/RDP-1024x396.jpg",
      },
      {
        title: "Remote IT Support",
        desc: "Receive quick and efficient IT support remotely, reducing downtime and increasing productivity.",
        image: "https://i.ibb.co/sRBK1BK/download-1.png",
      },
      {
        title: "Secure File Sharing",
        desc: "Share files securely with colleagues and clients, ensuring data privacy and compliance.",
        image: "https://i.ibb.co/cyj3Pwy/ba009f17334c055fb8ef1878cea5e710.jpg",
      },
      {
        title: "Remote Collaboration Tools",
        desc: "Collaborate with team members in real-time, share screens, and work on projects together remotely.",
        image: "https://i.ibb.co/rQyxQbc/download1.png",
      },
    ],

    flow: "",
    tech: [
      "React Native",
      "Node.js",
      "Javascript",
      "Java",
      "PostgreSQL",
      "MySQL",
      "MongoDB",
      "Express.js",
      "Html",
    ],
  },
  // * Software Consulting
  {
    page: "software-consulting",
    banner: {
      title: "Software Consulting",
      subtitle:
        "Web development refers to the process of creating websites and web applications that are accessible over the internet. It involves several tasks, including web design, web content development, client-side/server-side scripting, and network security configuration, among others.",
      video: "software-consulting",
    },
    service: [
      {
        icon: "../../../Assets/Images/web/106.svg",
        title: " System Implementation",
        desc: "Software Implementation: Planning and executing the deployment of new software systems within an organization. System Configuration: Customizing and configuring software systems to match business processes and workflows.",
        image: "https://i.ibb.co/R4mkzxT/pe-api-automation-Xe-WSdi2-J.png",
      },
      {
        icon: "../../../Assets/Images/web/108.svg",
        title: "Project Management",
        desc: "Agile Project Management: Using Agile methodologies to manage and deliver software projects efficiently. Traditional Project Management: Applying traditional project management approaches like Waterfall for software development.",
        image:
          "https://i.ibb.co/FhpTFzd/pe-application-modernization-Cs-Ko-Drqf.png",
      },
      {
        icon: "../../../Assets/Images/web/109.svg",
        title: "Mobile App Development",
        desc: "iOS App Development: Creating native iOS applications for iPhone and iPad devices. Android App Development: Developing native Android applications for smartphones and tablets.",
        image:
          "https://i.ibb.co/x723tys/pe-digital-transformation-CIcf6o75.png",
      },
      {
        icon: "../../../Assets/Images/web/110.svg",
        title: "Web Development",
        desc: "Frontend Development: Designing and building user interfaces for websites using HTML, CSS, and JavaScript. Backend Development: Developing server-side logic and databases for website functionality.",
        image: "https://i.ibb.co/9qcT1yW/pe-cms-development-Bo-Wqg-BX4.png",
      },
      {
        icon: "../../../Assets/Images/web/111.svg",
        title: "Cloud Computing",
        desc: "Cloud Infrastructure Setup: Configuring and deploying cloud infrastructure on platforms like AWS, Azure, and Google Cloud. Cloud Migration: Migrating existing applications and data to the cloud.",
        image: "https://i.ibb.co/BC58jWt/pe-mvp-development-BXZJ8-ZTa.png",
      },
      {
        icon: "../../../Assets/Images/web/112.svg",
        title: "API Automation",
        desc: "Streamlining business processes with API automation and integration, enhancing connectivity across systems.",
        image: "https://i.ibb.co/9c0Zg4B/pe-software-solutions-G2t7k-APj.png",
      },
    ],

    flow: "",
    tech: [
      "React Native",
      "Node.js",
      "Javascript",
      "Java",
      "PostgreSQL",
      "MySQL",
      "MongoDB",
      "Express.js",
      "Html",
    ],
  },
  // * LMS
  {
    page: "lms",
    banner: {
      title: "Learning Management System",
      subtitle:
        "Revolutionize Education with Our Cutting-Edge Learning Management Solutions",
      video: "lms-introduction",
    },
    service: [
      {
        icon: "../../../Assets/Images/lms/101.svg",
        title: "Course Management",
        desc: "Streamline your educational offerings with our comprehensive course management solutions. From creating and organizing courses to tracking student progress, our LMS empowers educators to deliver exceptional learning experiences.",
        image: "https://i.ibb.co/LCKtM0D/istockphoto-1312078072-612x612.jpg",
      },
      {
        icon: "../../../Assets/Images/lms/102.svg",
        title: "User Management",
        desc: "Efficiently manage your students, teachers, and administrators with our intuitive user management system. Our LMS provides robust tools for registration, profile management, and user roles, ensuring a seamless educational environment.",
        image: "https://i.ibb.co/Jvx7XkP/istockphoto-1142897762-612x612.jpg",
      },
      {
        icon: "../../../Assets/Images/lms/103.svg",
        title: "Assessment Tools",
        desc: "Enhance learning outcomes with our diverse assessment tools. Create quizzes, assignments, and exams with ease, and utilize advanced analytics to measure student performance and identify areas for improvement.",
        image: "https://i.ibb.co/K6QyZdt/assessment-tools.png",
      },
      {
        icon: "../../../Assets/Images/lms/104.svg",
        title: "Collaboration Features",
        desc: "Foster a collaborative learning environment with our integrated communication and collaboration features. Facilitate discussions, group projects, and peer-to-peer learning to engage students and promote teamwork.",
        image: "https://i.ibb.co/CHWYnwg/collaboration.png",
      },
    ],
    flow: "",
    tech: [
      "Moodle",
      "Canvas",
      "Blackboard",
      "PHP",
      "JavaScript",
      "MySQL",
      "PostgreSQL",
      "MongoDB",
      "HTML",
      "CSS",
    ],
  },
];
