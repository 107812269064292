/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React from 'react';
import useWindowWidth from '../../Utils/WindowCaptcher';
import Container from '../Container/Container';
import Title from '../Title/Title';
import BlogBox from './BlogBox/BlogBox';
import BlogData from '../../Pages/Blog/blogData';

const BlogSection = () => {
    const px1280 = useWindowWidth(1280);
    const px1024 = useWindowWidth(1024);
    const px500 = useWindowWidth(500);

    return (
        <div className="blog_section py-32" style={{ backgroundColor: '#f5f8ff' }}>
            <Container>
                <Title
                    title="Latest Blog"
                    subTitle="Welcome to Pixfar's Latest Blog Section! Here, we bring you insightful articles, industry trends, and expert perspectives on various topics related to software development, technology solutions, and business transformation. Stay up-to-date with the latest advancements, best practices, and success stories that can inspire and guide your digital journey."
                />

                <div
                    className={`grid ${
                        px500
                            ? 'grid-cols-1'
                            : px1024
                            ? 'grid-cols-2'
                            : px1280
                            ? 'grid-cols-3'
                            : 'grid-cols-4'
                    } gap-5`}
                >
                    {BlogData.map((data, index) => (
                        <BlogBox key={index} data={data} />
                    ))}
                </div>
            </Container>
        </div>
    );
};

export default BlogSection;
