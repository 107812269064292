/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { X } from 'react-feather';
import { SwiperSlide } from 'swiper/react';
import SwiperSlider from '../../SwiperSlide/SwiperSlide';

const PortfolioImageSlider = ({ data }) => (
    <>
        <input type="checkbox" id="portfolioImageSlider" className="modal-toggle" />
        <div className="modal">
            <div className="modal-box w-11/12 max-w-5xl">
                <label
                    htmlFor="portfolioImageSlider"
                    className="rounded-full absolute right-0 top-2 text-white cursor-pointer"
                    style={{
                        zIndex: '999999',
                        height: '40px',
                        width: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#008eff',
                    }}
                >
                    {/* ✕ */}
                    <X size={20} />
                </label>
                <SwiperSlider speed={4000}>
                    {data.map((image) => (
                        <SwiperSlide>
                            <img src={image.img} alt="" style={{ cursor: 'e-resize' }} />
                        </SwiperSlide>
                    ))}
                </SwiperSlider>
            </div>
        </div>
    </>
);

export default PortfolioImageSlider;
