import React from 'react';
import Bootstrap from '../../Assets/Images/Bootstrap.png';
import CSS from '../../Assets/Images/CSS.png';
import HTML from '../../Assets/Images/HTML.png';
import JS from '../../Assets/Images/JavaScript.png';
import mongoDB from '../../Assets/Images/MongoDB.png';
import nextJS from '../../Assets/Images/Nextjs.png';
import react from '../../Assets/Images/React.png';
import SCSS from '../../Assets/Images/Sass.png';
import ExpressJS from '../../Assets/Images/expressJS.png';
import nodeJS from '../../Assets/Images/nodeJS.png';
import Container from '../Container/Container';
import Heading from '../Heading/Heading';
import FrameWorkCss from './FrameWork.module.css';
import FrameWorkList from './FrameWorkList/FrameWorkList';

const FrameWork = () => (
  <div
    style={{
      backgroundImage: "url(https://i.ibb.co/5KDSC4j/White-Dots-D4-ib2-Mf.png)",
      backgroundRepeat: "repeat",
      backgroundSize: "70%",
      backgroundAttachment: "fixed",
      backgroundColor: "#000",
    }}
    className="py-24"
  >
    <div className={FrameWorkCss.frame_work}>
      <Container>
        <div className="flex flex-col justify-center items-center pb-5">
          <Heading first="Modern, " middle="Design Tools" />
          <p className="text-[#ffffff80] w-[50%] text-center">
            Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet consectetur
            adipisicing elit. Quisquam, suscipit?
          </p>
        </div>

        <ul>
          {/* First Row  */}
          <FrameWorkList logo={react} text="React" border="topLeft" />
          <FrameWorkList logo={mongoDB} text="MongoDB" border="topLeft" />
          <FrameWorkList logo={nextJS} text="Next JS" border="topLeft" />
          <FrameWorkList logo={nodeJS} text="Node JS" border="topLeft" />
          <FrameWorkList logo={ExpressJS} text="Express JS" border="topLeft" />
          <FrameWorkList logo={JS} text="Javascripts" border="topRight" />

          {/* Secound Row */}
          <FrameWorkList border="bottomLeft" />
          <FrameWorkList logo={HTML} text="HTML 5" border="topLeft" />
          <FrameWorkList logo={CSS} text="CSS 3" border="topLeft" />
          <FrameWorkList logo={SCSS} text="SCSS / SASS" border="topLeft" />
          <FrameWorkList logo={Bootstrap} text="Bootstrap 5" border="topLeft" />
          {/* <FrameWorkList border="bottomRight" /> */}
        </ul>
      </Container>
    </div>
  </div>
);

export default FrameWork;
