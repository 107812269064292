import React from 'react';
import BlogSection from '../../Components/BlogSection/BlogSection';
import PageTitle from '../../Components/PageTitle/PageTitle';
import ScrollToTop from '../../Components/ScrollToTop/ScrollToTop';

const Blog = () => (
    <div className="blog_page">
        <PageTitle title="Blog" current="Blog" />
        <ScrollToTop />
        <BlogSection />
        {/* <Testimonial /> */}
    </div>
);

export default Blog;
