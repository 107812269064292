import { AiFillStar, AiOutlineStar } from 'react-icons/ai';

// eslint-disable-next-line consistent-return
const Ratting = ({ rtn }) => {
    if (rtn === '1') {
        return (
            <ul className="flex gap-1">
                <li>
                    <AiFillStar size={17} color="#008eff" />
                </li>
                <li>
                    <AiOutlineStar color="#008eff75" size={17} />
                </li>
                <li>
                    <AiOutlineStar color="#008eff75" size={17} />
                </li>
                <li>
                    <AiOutlineStar color="#008eff75" size={17} />
                </li>
                <li>
                    <AiOutlineStar color="#008eff75" size={17} />
                </li>
            </ul>
        );
    }
    if (rtn === '2') {
        return (
            <ul className="flex gap-1">
                <li>
                    <AiFillStar color="#008eff" size={17} />
                </li>
                <li>
                    <AiFillStar color="#008eff" size={17} />
                </li>
                <li>
                    <AiOutlineStar color="#008eff75" size={17} />
                </li>
                <li>
                    <AiOutlineStar color="#008eff75" size={17} />
                </li>
                <li>
                    <AiOutlineStar color="#008eff75" size={17} />
                </li>
            </ul>
        );
    }
    if (rtn === '3') {
        return (
            <ul className="flex gap-1">
                <li>
                    <AiFillStar color="#008eff" size={17} />
                </li>
                <li>
                    <AiFillStar color="#008eff" size={17} />
                </li>
                <li>
                    <AiFillStar color="#008eff" size={17} />
                </li>
                <li>
                    <AiOutlineStar color="#008eff75" size={17} />
                </li>
                <li>
                    <AiOutlineStar color="#008eff75" size={17} />
                </li>
            </ul>
        );
    }
    if (rtn === '4') {
        return (
            <ul className="flex gap-1">
                <li>
                    <AiFillStar color="#008eff" size={17} />
                </li>
                <li>
                    <AiFillStar color="#008eff" size={17} />
                </li>
                <li>
                    <AiFillStar color="#008eff" size={17} />
                </li>
                <li>
                    <AiFillStar color="#008eff" size={17} />
                </li>
                <li>
                    <AiOutlineStar color="#008eff75" size={17} />
                </li>
            </ul>
        );
    }
    if (rtn === '5') {
        return (
            <ul className="flex gap-1">
                <li>
                    <AiFillStar color="#008eff" size={17} />
                </li>
                <li>
                    <AiFillStar color="#008eff" size={17} />
                </li>
                <li>
                    <AiFillStar color="#008eff" size={17} />
                </li>
                <li>
                    <AiFillStar color="#008eff" size={17} />
                </li>
                <li>
                    <AiFillStar color="#008eff" size={17} />
                </li>
            </ul>
        );
    }
};

export default Ratting;
