/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useParams } from 'react-router-dom';
import BlogData from '../../../Pages/Blog/blogData';
import Button from '../../Button/Button';
import Container from '../../Container/Container';
import Breadcrumbs from '../../breadcrumbs/breadcrumbs';

const images = 'https://cdn.geekboots.com/geek/javascript-meta-1652702081069.jpg';

const BlogSingle = () => {
    const { id } = useParams();
    const data = BlogData.filter((item) => item.id === Number(id))[0];
    console.log(data);
    return (
        <div className="blog_single" style={{ padding: '100px 0', backgroundColor: 'white' }}>
            <Container>
                <div className="blog_title border border-slate-600  rounded-lg p-2">
                    <h1 className="text-3xl font-semibold mb-2">{data.title}</h1>
                    <Breadcrumbs link1={{ name: 'Blog', link: '/blog' }} current={data.title} />
                </div>

                <img
                    src={data.image || images}
                    className="w-full mt-5 rounded-md"
                    alt="Blog Image"
                />

                <div className="mt-5">
                    <p>{data.desc}</p>
                </div>

                <Button id="offcanvas" text="Request a Quote" className="mt-10" />
            </Container>
        </div>
    );
};
export default BlogSingle;
