import React from 'react';
import { ArrowRight, Calendar, Eye } from 'react-feather';
import { Link } from 'react-router-dom';
import useWindowWidth from '../../../Utils/WindowCaptcher';
import blogSingleCss from '../../PortfolioSection/PortfolioSection.module.css';

const BlogBox = ({ data }) => {
    const width1124 = useWindowWidth(1124);
    return (
        <div
            className={`card w-full bg-base-100 border rounded-md shadow-md ${blogSingleCss.portfolio}`}
        >
            <figure>
                <img
                    src={data.image}
                    alt="Shoes"
                    style={{
                        height: '250px',
                        width: '100%',
                        objectFit: 'cover',
                    }}
                />
            </figure>
            <div className={`card-body bg-white ${width1124 ? 'px-2 py-3' : 'px-5 py-4'}`}>
                <div className="flex justify-between">
                    <p className="flex items-center gap-1 text-gray-500">
                        <Eye color="#008eff" size={25} /> {data.views}k
                    </p>
                    <p className={`flex gap-1 items-center justify-end text-gray-500 `}>
                        <Calendar color="#008eff" size={25} /> {data.date}
                    </p>
                </div>
                <Link to={`/blog/${data.id}`}>
                    <h1 style={{ fontSize: '21px', color: '#008eff' }} className="mb-2 blog_title">
                        {data.title}
                    </h1>
                </Link>
                <p className={`text-gray-600 mb-2 ${blogSingleCss.desc}`}>{data.desc}</p>
                <Link
                    to={`/blog/${data.id}`}
                    className="flex items-center gap-1"
                    style={{ color: '#008eff' }}
                >
                    Read More <ArrowRight />
                </Link>
            </div>
        </div>
    );
};

export default BlogBox;
