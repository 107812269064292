/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../../Components/Button/Button';
import Container from '../../../Components/Container/Container';
import PortfolioImageSlider from '../../../Components/PortfolioSection/ImageSlider/PortfolioImageSlider';
import portfolioData from '../../../Components/PortfolioSection/PortfolioBox/protfolioData';
import ScrollToTop from '../../../Components/ScrollToTop/ScrollToTop';
import Breadcrumbs from '../../../Components/breadcrumbs/breadcrumbs';

const PortfolioSingle = () => {
    const { id } = useParams();
    const portfolio = portfolioData?.filter((data) => data?.id === Number(id))[0];

    console.log(portfolio.image);

    return (
        <div className="portfolio_single" style={{ padding: '100px 0' }}>
            <ScrollToTop />
            <Container>
                <div className="portfolio_title border border-slate-600  rounded-lg p-2">
                    <h1 className="text-3xl font-semibold mb-2">{portfolio.title}</h1>
                    <Breadcrumbs
                        link1={{ name: 'Portfolio', link: '/portfolio' }}
                        current="Portfolio Item"
                    />
                </div>

                <div className="grid grid-cols-3 gap-5 mt-10">
                    <PortfolioImageSlider data={portfolio?.image} />
                    {portfolio?.image?.map((image) => (
                        <label htmlFor="portfolioImageSlider" style={{ cursor: 'zoom-in' }}>
                            <img
                                src={image.img}
                                alt=""
                                className="w-full rounded-lg"
                                data-aos="fade-up"
                            />
                        </label>
                    ))}
                </div>

                <div className="mt-10">
                    <p>{portfolio?.desc}</p>
                </div>

                <Button id="offcanvas" text="Request a Quote" className="mt-10" />
            </Container>
        </div>
    );
};

export default PortfolioSingle;
