import React from 'react';
import { Mail } from 'react-feather';

const Email = () => (
    <a
        href="mailto:query@pixfar.com"
        className="flex gap-1 items-center"
        style={{ color: 'white' }}
    >
        <Mail size={18} /> query@pixfar.com
    </a>
);

export default Email;
