import React from 'react';
import { ArrowRight } from 'react-feather';
import { Link } from 'react-router-dom';
import react from '../../../Assets/Images/Sass.png';

const ServiceMobileBox = ({ services }) => {
    const { serviceImage, banner, data, link } = services;

    return (
        <div className="car w-full shadow-xl rounded-lg" style={{ backgroundColor: '#fff' }}>
            <figure className="px-10 pt-10 text-center w-full">
                <img
                    src={serviceImage || react}
                    alt=""
                    style={{
                        width: '120px',
                        height: '120px',
                        objectFit: 'contain',
                        margin: '0 auto',
                    }}
                />
            </figure>
            <div className="card-body items-center text-center p-2 pb-7 ">
                <div className="card-title">
                    <h1 style={{ color: '#008eff' }}>{banner?.title || 'Lorem Hello'}</h1>
                </div>
                <p
                    className="line_shorter text-sm"
                    dangerouslySetInnerHTML={{ __html: data?.desc || 'N/A' }}
                />
                <Link to={`/services/${link}`} className="border rounded-full p-3 mt-3">
                    <ArrowRight color="#00000050" />
                </Link>
            </div>
        </div>
    );
};

export default ServiceMobileBox;
