import React, { useState } from 'react';
import inputCss from '../formElement.module.css';

const Input = ({ id, label, icon, className }) => {
    const [input, setInput] = useState('');
    return (
      <label className={inputCss.label}>
        <p className="text-[#ffffffa9] !-mb-4 mt-4 text-[15px]">{label}</p>

        <input
          type="text"
          id={id}
          onKeyUp={(e) => setInput(e.target.value)}
          className={`input input-bordered !bg-[#202020] w-full ${className}`}
        />
      </label>
    );
};

export default Input;
