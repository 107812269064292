/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import bgNews from '../../Assets/Images/download.jpeg';
import Container from '../Container/Container';
import InputGroup from '../FormElement/InputGroup/InputGroup';
import NewslatterCss from './Newslatter.module.css';

const Newslatter = () => {
    const api =
        'https://gmail.us21.list-manage.com/subscribe/post?u=461732751c82150652e4389f7&amp;id=fc9abc60a6&amp;f_id=00e5d5e6f0';
    const [data, setData] = useState();
    return (
        <div
            className={`${NewslatterCss.newslatter} bg-fixed `}
            style={{
                backgroundImage: `url(${bgNews})`,
            }}
        >
            <Container>
                <div>
                    <h2>Newsletter</h2>
                    <MailchimpSubscribe
                        url={api}
                        render={({ subscribe, status }) => (
                            <>
                                <form
                                    method="POST"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        subscribe({ EMAIL: data });
                                    }}
                                >
                                    <InputGroup setData={setData} />
                                </form>
                                <p className="text-light">{status}</p>
                            </>
                        )}
                    />
                    <p>
                        Don't miss out on the opportunity to revolutionize your business with
                        Pixfar's innovative software solutions. Subscribe to our newsletter today
                        and join our community of forward-thinking professionals.
                    </p>
                </div>
            </Container>
        </div>
    );
};
export default Newslatter;
