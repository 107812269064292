import React from 'react';
import image from "../../Assets/Images/product-design.svg";

function ServiceBox({
  isLast,
  img,
  title,
  icon,
  active,
  setActive,
  bodyImg,
  desc,
  link,
  setBodyData,
  isButton,
}) {
  return (
    <div className="h-[130px] w-[150px] xl:w-[200px] relative">
      <button
        onClick={() => {
          setActive(title);
          setBodyData({
            bodyImg: bodyImg,
            desc: desc,
            link: link,
            title: title,
          });
        }}
        className={`h-full w-full border absolute bottom-0 bg-[#141414] border-[#ffffff40] serviceHover ${
          active === title && "!h-[180px]"
        } hover:h-[180px] p-5 flex items-start flex-col ${
          !isLast && "border-r-0"
        }`}
      >
        <div className="h-[40px] w-[40px] bg-[#04365C] items-center justify-center flex rounded-md shadow">
          <img src={img ? icon : image} alt="" className="h-[30px] w-[30px] " />
        </div>
        <h1 className="mt-3 text-left">{title}</h1>
      </button>
    </div>
  );
}

export default ServiceBox 