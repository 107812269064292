import React from 'react';
import Container from '../Container/Container';
import Breadcrumbs from '../breadcrumbs/breadcrumbs';
import pageTitleCss from './PageTittle.module.css';

const PageTitle = ({ title, videoSrc, subTitle, link1, link2, link3, link4, current }) => (
    <div
        // style={{
        //     backgroundImage: `url(${img || 'https://i.ibb.co/J5GyFnf/pixfar-image.jpg'})`,
        // }}
        className={pageTitleCss.page_title}
    >
        <div className="absolute inset-0 overflow-hidden -z-10">
            <video autoPlay muted loop playsInline className="w-full h-full object-cover">
                <source src={videoSrc} type="video/mp4" />
            </video>
            <div className="absolute inset-0 bg-gradient-to-b from-black to-transparent opacity-80" />
        </div>
        <Container>
            <h1 className="text-center">{title}</h1>

            <div className="mx-auto w-full">
                <Breadcrumbs
                    link1={link1}
                    link2={link2}
                    link3={link3}
                    link4={link4}
                    current={current}
                />
            </div>
            <p className="text-white text-center md:odd:w-[70%] mx-auto">{subTitle}</p>
        </Container>
    </div>
);

export default PageTitle;
