import React from 'react';
import { SwiperSlide } from 'swiper/react';
import SwiperSlider from '../../SwiperSlide/SwiperSlide';
import PortfolioBox from '../PortfolioBox/PortfolioBox';

const PortfolioMobileSlider = () => (
    <SwiperSlider type="portfolio" speed={2000}>
        <SwiperSlide>
            <PortfolioBox />
        </SwiperSlide>
        <SwiperSlide>
            <PortfolioBox />
        </SwiperSlide>
        <SwiperSlide>
            <PortfolioBox />
        </SwiperSlide>
        <SwiperSlide>
            <PortfolioBox />
        </SwiperSlide>
        <SwiperSlide>
            <PortfolioBox />
        </SwiperSlide>
    </SwiperSlider>
);

export default PortfolioMobileSlider;
