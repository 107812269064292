import React from 'react';

const InputGroup = ({ setData }) => (
    <div className="form-control">
        <label className="input-group" htmlFor="#">
            <input
                type="text"
                placeholder="Type your Email"
                onChange={(e) => setData(e.target.value)}
                className="input input-bordered"
            />
            <button
                type="submit"
                style={{
                    backgroundColor: '#008eff',
                    padding: '0 20px',
                    color: 'white',
                }}
            >
                Subscribe
            </button>
        </label>
    </div>
);

export default InputGroup;
