import React from 'react';
import { Phone } from 'react-feather';

const Number = () => (
    <a href="callto:+8809638201690" className="flex gap-1 items-center" style={{ color: 'white' }}>
        <Phone size={18} />
        +8809638201690
    </a>
);

export default Number;
