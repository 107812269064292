const portfolioData = [
  {
    id: 1,
    title: "Fluxx Mobile Apps",
    thumb: "https://i.ibb.co/MpM8W3x/Screenshot-from-2024-06-22-20-54-05.png",
    image: [
      {
        id: 1,
        img: "https://i.ibb.co/3kNBgCT/Screenshot-from-2024-06-22-20-56-20.png",
      },
      {
        id: 2,
        img: "https://i.ibb.co/HLySrNM/Screenshot-from-2024-06-22-20-56-27.png",
      },
      {
        id: 3,
        img: "https://i.ibb.co/tz1yZfq/Screenshot-from-2024-06-22-20-56-38.png",
      },
      {
        id: 4,
        img: "https://i.ibb.co/Fq3pnQV/Screenshot-from-2024-06-22-20-56-44.png",
      },
      {
        id: 5,
        img: "https://i.ibb.co/pyHdBVV/Screenshot-from-2024-06-22-20-58-04.png",
      },
    ],
    desc: "Fluxx is the ultimate expense and tax management app designed to streamline your financial journey. Effortlessly track your expenses, stay organized throughout the year. With intuitive features, Fluxx simplifies tax preparation by generating comprehensive reports and summaries, ensuring you're ready for tax time. Capture receipts, manage deductions, and gain insights into your spending patterns with ease.",
  },
  {
    id: 1,
    title: "Code DNA",
    thumb: "https://i.ibb.co/Ry3LTjb/image.png",
    image: [
      {
        id: 1,
        img: "https://i.ibb.co/wR59NfL/image.png",
      },
      {
        id: 2,
        img: "https://i.ibb.co/4RtTKc2/image.png",
      },
      {
        id: 3,
        img: "https://i.ibb.co/R0QBn5j/image.png",
      },
      {
        id: 4,
        img: "https://i.ibb.co/r4hpbd0/image.png",
      },
      {
        id: 5,
        img: "https://i.ibb.co/3RKjT7c/image.png",
      },
      {
        id: 6,
        img: "https://i.ibb.co/GQd5Bch/image.png",
      },
    ],
    desc: "",
  },
  {
    id: 2,
    title: "Nijanand Pharma",
    thumb: "https://i.ibb.co/31KwdYJ/image.png",
    image: [
      {
        id: 1,
        img: "https://i.ibb.co/31KwdYJ/image.png",
      },
      {
        id: 2,
        img: "https://i.ibb.co/B6Nz1K6/image.png",
      },
      {
        id: 3,
        img: "https://i.ibb.co/w0GXRQ2/image.png",
      },
      {
        id: 4,
        img: "https://i.ibb.co/pWrJ8X4/image.png",
      },
      {
        id: 5,
        img: "https://i.ibb.co/znpL89K/image.png",
      },
      {
        id: 6,
        img: "https://i.ibb.co/D9DD4zT/image.png",
      },
    ],
    desc: "",
  },
  {
    id: 3,
    title: "Hammamretreat SPA Shop",
    thumb: "https://i.ibb.co/MPpjVwp/image.png",
    image: [
      {
        id: 1,
        img: "https://i.ibb.co/MPpjVwp/image.png",
      },
      {
        id: 2,
        img: "https://i.ibb.co/MPpjVwp/image.png",
      },
      {
        id: 3,
        img: "https://i.ibb.co/Nm1PyBk/image.png",
      },
      {
        id: 4,
        img: "https://i.ibb.co/gVvhMjn/image.png",
      },
      {
        id: 5,
        img: "https://i.ibb.co/VtQj0WW/image.png",
      },
      {
        id: 6,
        img: "https://i.ibb.co/xCH6tvX/image.png",
      },
    ],
    desc: "",
  },
  {
    id: 4,
    title: "electric fuel",
    thumb: "https://i.ibb.co/fdq09ts/image.png",
    image: [
      {
        id: 1,
        img: "https://i.ibb.co/fdq09ts/image.png",
      },
      {
        id: 2,
        img: "https://i.ibb.co/y8TKbG0/image.png",
      },
      {
        id: 3,
        img: "https://i.ibb.co/9GzFgND/image.png",
      },
      {
        id: 4,
        img: "https://i.ibb.co/ZVc6Gzd/image.png",
      },
      {
        id: 5,
        img: "https://i.ibb.co/sgvwhnC/image.png",
      },
      {
        id: 6,
        img: "https://i.ibb.co/RH5qb1p/image.png",
      },
    ],
    desc: "",
  },
];

export default portfolioData;
