/* eslint-disable react/no-danger */
import React from 'react';
import titleCss from './Title.module.css';

const Title = ({ title, subTitle, className }) => (
    <div className={`${titleCss.title} m-auto mb-10`}>
        <h1
            className={` text-5xl font-bold text-center ${className}`}
            dangerouslySetInnerHTML={{ __html: title }}
        />
        <span className={titleCss.span} />
        <p className="mb-5 text-center">{subTitle}</p>
    </div>
);

export default Title;
