import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../../../Assets/Images/logo.png";

import useWindowWidth from '../../../Utils/WindowCaptcher';
import Container from '../../Container/Container';
import HeaderCss from '../../Header/Header.module.css';
import MenuList from '../../Header/MenuList/MenuList';
import MobileMenu from '../../Header/Mobile/MobileMenu';
import bannerCss from './BannerElement.module.css';

// eslint-disable-next-line no-unused-vars
const BannerElement = ({ children , video, single}) => {
    const px600 = useWindowWidth(601);
    const width1280 = useWindowWidth(1280);

    return (
      <>
        <div className={bannerCss.banner}>
          <div className="w-full bg-transparent absolute top-0 left-0">
            <Container>
              {width1280 ? (
                <MobileMenu />
              ) : (
                <div className="flex justify-between items-center w-full py-3">
                  <div className={HeaderCss.logo}>
                    <Link to="/">
                      <img src={logo} alt="logo" className="w-[30px]" />
                      <h1
                        style={{
                          fontSize: "30px",
                          fontWeight: "700",
                          position: "relative",
                          color: "#fff",
                        }}
                      >
                        ixfar
                      </h1>
                    </Link>
                  </div>

                  <div className={HeaderCss.menu}>
                    <MenuList />
                  </div>

                  <Link to="/contact-us" className="btn">
                    Get in Touch
                  </Link>
                </div>
              )}
            </Container>
          </div>
          <div className="absolute inset-0 overflow-hidden -z-10">
            <video
              autoPlay
              muted
              loop
              playsInline
              className="w-full h-full object-cover "
            >
              <source src={video} type="video/mp4" />
            </video>
          </div>
          <div className="absolute inset-0 bg-[#00000050] -z-10 h-full w-full" />
          <div className="absolute inset-0 bg-gradient-to-r -z-10 from-black to-transparent h-full w-full" />

          {children}
        </div>
      </>
    );
};



export default BannerElement;
