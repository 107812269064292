/* eslint-disable no-nested-ternary */
import React from 'react';
import serviceBannerImage from '../../Assets/Images/Service-banner-image.jpg';
import Container from '../../Components/Container/Container';
import FrameWork from '../../Components/FrameWork/FrameWork';
import LogoSlider from '../../Components/LogoSlider/LogoSlider';
import Newslatter from '../../Components/Newslatter/Newslatter';
import PageTitle from '../../Components/PageTitle/PageTitle';
import ServiceMobileBox from '../../Components/ServiceSection/ServiceMobileSection/ServiceMobileBox';
import Title from '../../Components/Title/Title';
import useWindowWidth from '../../Utils/WindowCaptcher';
import sectionData from './ServiceData/sectionData';

const Service = () => {
    const px1280 = useWindowWidth(1280);
    const px1024 = useWindowWidth(1024);
    const px450 = useWindowWidth(500);
    return (
      <>
        <PageTitle
          img={serviceBannerImage}
          title="Services"
          current="Services"
        />
        {/* <ServiceSection /> */}
        <div className="py-10" style={{ backgroundColor: "#f5f8ff" }}>
          <Container>
            <Title title="Our Services" />
            <div
              className={`grid grid-cols-${
                px450 ? "1" : px1024 ? "2" : px1280 ? "3" : "4"
              } gap-5`}
            >
              {sectionData.map((data, index) => (
                <ServiceMobileBox key={index} services={data} />
              ))}
            </div>
          </Container>
        </div>
        <FrameWork />
        <Newslatter />
        <LogoSlider />
      </>
    );
};

export default Service;
