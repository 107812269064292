import React from "react";

function LogoBox({ image }) {
  return (
    <div className="flex justify-center items-center h-[90px] w-[200px] bg-[#3d3d3dd5] ml-5 rounded">
      <img
        src={image || "https://i.ibb.co/ys46tv5/ic-launcher-round.png"}
        className="object-contain h-[70px] w-[150px]"
      />
    </div>
  );
}

export default LogoBox;
