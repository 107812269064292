import React from 'react';

function Heading({ first, middle, last, middleRight }) {
  return (
    <h1 className="text-center mb-[14px] text-4xl font-normal text-white">
      {first}{" "}
      <span className="bg-gradient-to-r from-fuchsia-500 to-cyan-500 bg-clip-text text-transparent">
        {middle} {middleRight}
      </span>{" "}
      <br /> {last}
    </h1>
  );
}

export default Heading