import React from 'react';
import video from '../../Assets/videos/techvideoBg.mp4';
import BannerElement from '../../Components/Banner/BannerElement/BannerElement';
import ContactForm from '../../Components/ContactForm/ContactForm';
import Container from '../../Components/Container/Container';
import ScrollToTop from '../../Components/ScrollToTop/ScrollToTop';

const Contact = () => (
  <>
    {/* <PageTitle title="Contact Us" current="Contact Us" /> */}
    <BannerElement video={video}>
      <Container>
        <h1 className="font-[800] text-[50px] text-center" style={{ lineHeight: 1.2 }}>
          Contact Us
        </h1>
      </Container>
    </BannerElement>
    
    <ScrollToTop />
    {/* <Map /> */}
    <ContactForm />
  </>
);

export default Contact;
