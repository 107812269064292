/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import aboutUsBg from '../../Assets/videos/aboutUS.mp4';
import BannerElement from '../../Components/Banner/BannerElement/BannerElement';
import BestForAbout from '../../Components/BestForAbout/BestForAbout';
import Button from '../../Components/Button/Button';
import Container from '../../Components/Container/Container';
import ScrollToTop from '../../Components/ScrollToTop/ScrollToTop';
import AboutCSS from './About.module.css';
const bannerBg =
  "https://res.cloudinary.com/pixeldust-tech/video/upload/v1713437170/pixeldust-website/itqqo4bz4ibkwzkrxptt.mp4";

const About = () => (
  <div className={AboutCSS.about_page}>
    {/* <div className="">
            <PageTitle
                title="About Pixfar"
                // subTitle="Advancing the Frontier of Innovation At Pixfar Technologies, we redefine the landscape of technological advancement, offering unparalleled expertise in navigating the intricacies of modern tech. Our suite of cutting-edge solutions empowers organizations to harness the full potential of their digital transformations."
                current="About"
                videoSrc={aboutUsBg}
            />
        </div> */}

    <BannerElement video={aboutUsBg}>
      <Container>
        <h1 className="font-[800] text-[50px]" style={{ lineHeight: 1.2 }}>
          Advancing the Frontier <br /> of Innovation
        </h1>
        <p className="text-[#ffffff99] w-[50%] mt-5">
          At Pixfar Technologies, we redefine the landscape of technological
          advancement, offering unparalleled expertise in navigating the
          intricacies of modern tech. Our suite of cutting-edge solutions
          empowers organizations to harness the full potential of their digital
          transformations.
        </p>
      </Container>
    </BannerElement>

    <ScrollToTop />

    <div className="bg-black">
      <Container>
        <div
          className={`grid grid-cols-2  items-center py-32 ${AboutCSS.short_disc}`}
        >
          <img
            src="https://i.ibb.co/HxgpX2y/pexels-kevin-ku-577585.jpg"
            alt=""
          />
          <div>
            <h1>A Short Introduction</h1>
            <p className="text-gray-300 text-[16px]">
              <strong className="bg-gradient-to-r from-fuchsia-500 to-cyan-500 bg-clip-text text-transparent">
                Pixfar
              </strong>{" "}
              is a software company that specializes in ERP, POS, Big Data, and
              Application Development solutions. We help businesses of all sizes
              streamline their operations, improve their efficiency, and make
              better decisions.
            </p>
            <p className="text-gray-300 text-[16px]">
              <strong className="bg-gradient-to-r from-fuchsia-500 to-cyan-500 bg-clip-text text-transparent">
                At Pixfar
              </strong>
              , we're dedicated to transforming businesses of all sizes into
              agile, data-driven enterprises. With a deep-rooted commitment to
              innovation and excellence, we specialize in a range of
              cutting-edge software solutions that empower organizations to
              thrive in today's fast-paced digital landscape.
            </p>
            <Button id="offcanvas" text="Request a Quote" />
          </div>
        </div>
      </Container>
    </div>

    <BestForAbout />
    {/* <Newslatter /> */}
  </div>
);

export default About;
