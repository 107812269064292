/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Mail, Phone } from 'react-feather';
import { BiMessageDetail, BiUserCircle } from 'react-icons/bi';
import { BsTelephone } from 'react-icons/bs';
import { MdOutlineMailOutline, MdPlace } from 'react-icons/md';
import { VscTypeHierarchySub } from 'react-icons/vsc';
import useWindowWidth from '../../Utils/WindowCaptcher';
import Button from '../Button/Button';
import Container from '../Container/Container';
import Input from '../FormElement/Input/Input';
import Textarea from '../FormElement/TextArea/Textarea';
import SocialIcon from '../SocialIcon/SocialIcon';
import Title from '../Title/Title';
import formCss from './contactForm.module.css';

const ContactForm = () => {
    // const navigate = useNavigate();
    const px1024 = useWindowWidth(1024);
    const px768 = useWindowWidth(768);
    const px500 = useWindowWidth(500);
    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     navigate('https://request-quote.pixfar.com');
    // };
    return (
        <div className={formCss.contact_form + " bg-[#000] py-24"}>
            <Title
                title="Let's help you!"
                subTitle="We’d love to build something great together."
            />
            <Container>
                <div className={`${px768 ? 'block' : 'grid'} grid-cols-2 gap-10`}>
                    <div
                        // onClick={handleSubmit}
                        className={`border bg-[#ffffff27] border-[#ffffff50] pb-4 px-5 pt-3 ${
                            px500 ? 'w-full' : px768 ? 'w-2/3' : !px1024 && 'w-2/3'
                        } rounded-lg m-auto shadow-xl`}
                    >
                        <Input icon={<BiUserCircle size={23} />} label="Name" className="mt-4" />
                        <Input
                            icon={<MdOutlineMailOutline size={20} />}
                            label="Email"
                            className="mt-4"
                        />
                        <Input icon={<BsTelephone size={19} />} label="Phone" className="mt-4" />
                        <Input
                            icon={<VscTypeHierarchySub size={20} />}
                            label="Subject"
                            className="mt-4"
                        />
                        <Textarea
                            icon={<BiMessageDetail size={21} />}
                            label="Message"
                            className="mt-4"
                        />
                        <a
                            target="_blank"
                            href="https://request-quote.pixfar.com"
                            className="cursor-pointer"
                            rel="noreferrer"
                        >
                            <Button
                                type="button"
                                text="Send Message"
                                className="w-full mt-4 pointer-events-none"
                            />
                        </a>
                        <p className="mt-2 text-center text-[#ffffff70]" style={{ fontSize: '14px' }}>
                            We will get back to you within 24 hours.
                        </p>
                    </div>
                    <div className={formCss.contact_info}>
                        <img
                            src="https://adoctor.in/wp-content/uploads/2020/09/contact-1024x763.png"
                            alt=""
                            style={{
                                width: px1024 ? '70%' : '60%',
                                margin: px768 && 'auto',
                                marginTop: px768 && '20px',
                            }}
                        />
                        <hr className="my-5" />
                        <h1 className="text-2xl" style={{ color: '#008eff' }}>
                            Get In Touch
                        </h1>
                        <a href="/" className="flex items-center gap-2 mt-3">
                            <div
                                className="p-2 rounded-full border"
                                style={{ borderColor: '#008eff', color: '#008eff' }}
                            >
                                <Phone />
                            </div>
                            <p className="text-[#ffffff90]">+8809638201690</p>
                        </a>
                        <a href="/" className="flex items-center gap-2 mt-3">
                            <div
                                className="p-2 rounded-full border"
                                style={{ borderColor: '#008eff', color: '#008eff' }}
                            >
                                <Mail />
                            </div>
                            <p className="text-[#ffffff90]">query@pixfar.com</p>
                        </a>
                        <a href="/" className="flex items-center gap-2 mt-3">
                            <div
                                className="p-2 rounded-full border"
                                style={{ borderColor: '#008eff', color: '#008eff' }}
                            >
                                <MdPlace size={25} />
                            </div>
                            <p className="text-[#ffffff90]">House #12, Road-08, Block-D, Mirpur-11, Dhaka 1216, Bangladesh.</p>
                        </a>
                        <div className="mt-5">
                            <SocialIcon />
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default ContactForm;
