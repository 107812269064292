/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import serviceImage from "../../../../Assets/Images/erp/erpBanner.png";
import video from "../../../../Assets/videos/erp.mp4";
import BannerElement from "../../../../Components/Banner/BannerElement/BannerElement";
import Container from "../../../../Components/Container/Container";
import FrameWorkCss from "../../../../Components/FrameWork/FrameWork.module.css";
import FrameWorkList from "../../../../Components/FrameWork/FrameWorkList/FrameWorkList";
import Heading from "../../../../Components/Heading/Heading";
import ServiceBody from "../../../../Components/ServiceBox/ServiceBody";
import ServiceBox from "../../../../Components/ServiceBox/ServiceBox";
import useWindowWidth from "../../../../Utils/WindowCaptcher";
import { pageData } from "../../ServiceData/pageData";


import python from '../../../../Assets/Images/erp/Python-logo.png';
import docker from "../../../../Assets/Images/erp/docker.png";
import erpNext from '../../../../Assets/Images/erp/erpNext.png';
import Cloud from "../../../../Assets/Images/erp/frappe-cloud-logo.png";
import frappe from '../../../../Assets/Images/erp/frappe.jpg';
import mariaDB from "../../../../Assets/Images/erp/mariadb.png";
import Accordion from "../../../../Components/ServiceSection/Services/BannerServiceBox/ServiceBoxMobile";

const Erp = () => {
  const linkName = useParams().name;
  const data = pageData.filter((data) => data.page === 'erp')[0];
  const px645 = useWindowWidth(645);
  const px1280 = useWindowWidth(1280);
  const px1024 = useWindowWidth(1024);
  const width980 = useWindowWidth(1280);
  const [active, setActive] = useState(data.service[0].title);


  const [bodyData, setBodyData] = useState({
    bodyImg: data.service[0].image,
    desc: data.service[0].desc,
    link: "/services/pos",
    title: data.service[0].title,
    img: "",
  });

  return (
    <div className="service_single_page">
      <BannerElement video={video}>
        <Container>
          <div className="text-center">
            <div className="text-center text-[35px] font-bold">
              {data?.banner?.title}
            </div>
            <p className="w-[50%] mx-auto text-[#ffffff80] text-center text-[15px]">
              {data?.banner.subtitle}
            </p>
          </div>
        </Container>
      </BannerElement>

      <div
        style={{
          backgroundImage: ``,
          backgroundRepeat: "repeat",
          backgroundSize: "70%",
          backgroundAttachment: "fixed",
          backgroundColor: "#000",
        }}
        className="py-24"
      >
        <Container>
          {width980 &&
            data.service.map((item, index) => {
              return (
                <div key={index}>
                  <Accordion item={item} noBtn />
                </div>
              );
            })}

          {!width980 && (
            <>
              <ServiceBody title={active} bodyData={bodyData} />

              <div className="flex justify-center items-end -mt-12">
                {data.service.map((item, index) => (
                  <div className="" key={index}>
                    <ServiceBox
                      active={active}
                      setActive={setActive}
                      title={item.title}
                      icon={item.icon}
                      bodyImg={item.image}
                      desc={item.desc}
                      link={item.link}
                      setBodyData={setBodyData}
                      isLast={index === data.service.length - 1}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </Container>
      </div>

      <div className="bg-black py-24">
        <Container>
          <div className="flex flex-col justify-center items-center pb-5">
            <Heading first="Creativity Led, " middle="User Focused" />
            <p className="text-[#ffffff80] w-[50%] text-center">
              Enterprise Resource Planning (ERP) integrates various business
              processes into a single system, enhancing efficiency, data
              accuracy, and decision-making capabilities across an organization.
              It streamlines operations by providing a unified platform for
              finance, human resources, supply chain, and customer relationship
              management.
            </p>
          </div>

          <div className="flex justify-center items-center">
            <img src={serviceImage} alt="" />
          </div>
        </Container>
      </div>

      <div
        style={{
          backgroundImage:
            "url(https://i.ibb.co/5KDSC4j/White-Dots-D4-ib2-Mf.png)",
          backgroundRepeat: "repeat",
          backgroundSize: "70%",
          backgroundAttachment: "fixed",
          backgroundColor: "#000",
        }}
        className="py-24"
      >
        <div className={FrameWorkCss.frame_work}>
          <Container>
            <div className="flex flex-col justify-center items-center pb-5">
              <Heading first="Modern, " middle="ERP Tools" />
              <p className="text-[#ffffff80] w-[50%] text-center">
                Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Quisquam, suscipit?
              </p>
            </div>

            <ul>
              {/* First Row  */}
              <FrameWorkList border="bottomLeft" />
              <FrameWorkList logo={frappe} text="Frappe" border="topLeft" />
              <FrameWorkList logo={python} text="Python" border="topLeft" />
              <FrameWorkList logo={erpNext} text="ERP Next" border="topLeft" />
              <FrameWorkList
                logo={Cloud}
                text="Frappe Cloud"
                border="topLeft"
              />
              <FrameWorkList border="bottomLeft" />
              <FrameWorkList border="bottomLeft" />
              <FrameWorkList border="bottomLeft" />
              <FrameWorkList logo={mariaDB} text="Maria DB" border="topLeft" />
              <FrameWorkList logo={docker} text="Docker" border="topLeft" />

              {/* Secound Row */}
              {/* <FrameWorkList border="bottomRight" /> */}
            </ul>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Erp;