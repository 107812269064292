import React from 'react';
import { Link } from 'react-router-dom';
import buttonCss from './buton.module.css';

const Button = ({ text, handler, className, type, link, id }) => {
    if (type === 'button') {
        return (
            <button
                onClick={handler}
                className='btn'
                type="button"
            >
                {text}
            </button>
        );
    }
    if (id) {
        return (
            <a
                href="https://request-quote.pixfar.com"
                target="_new"
                className={`btn`}
            >
                {text}
            </a>
        );
    }
    if (type === 'submit') {
        return (
            <button
                className={`${buttonCss.button_hover} btn rounded-md ${className}`}
                type="submit"
                style={{ backgroundColor: '#008eff', color: 'white' }}
            >
                {text}
            </button>
        );
    }
    if (link) {
        return (
            <Link
                to={link || '/'}
                className={`${buttonCss.button_hover} btn rounded-md ${className}`}
                style={{ backgroundColor: '#008eff', color: 'white' }}
            >
                {text}
            </Link>
        );
    }
    if (type === 'load') {
        <button
            className="btn rounded-md loading"
            type="button"
            style={{ backgroundColor: '#008eff', color: 'white' }}
        >
            loading
        </button>;
    }
};

export default Button;
