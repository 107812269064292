import React from 'react';
import { Facebook, GitHub, Linkedin, Youtube } from 'react-feather';

const SocialIcon = () => (
    <ul className="flex gap-3 items-center" style={{ color: 'white' }}>
        <li>
            <a href="https://www.facebook.com/pixfarbd" target="_blank" rel="noreferrer">
                <Facebook size={18} color="#008eff" />
            </a>
        </li>
        <li>
            <a href="https://linkedin.com/company/pixfar" target="_blank" rel="noreferrer">
                <Linkedin size={18} color="#008eff" />
            </a>
        </li>
        <li>
            <a href="https://github.com/pixfar" target="_blank" rel="noreferrer">
                <GitHub size={18} color="#008eff" />
            </a>
        </li>
        <li>
            <a href="https://www.youtube.com/@pixfar" target="_blank" rel="noreferrer">
                <Youtube size={18} color="#008eff" />
            </a>
        </li>
    </ul>
);

export default SocialIcon;
