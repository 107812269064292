import React from 'react';
import { ArrowLeft } from 'react-feather';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <button type="button" className="btn" onClick={handleBack} style={{ color: 'white' }}>
            <ArrowLeft size={20} />
            Back
        </button>
    );
};

export default BackButton;
