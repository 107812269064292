/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Menu, X } from 'react-feather';
import logo from '../../../Assets/Images/logo.png';
import MobileMenuOffcanvas from './MobileMenuOffcanvas/MobileMenuOffcanvas';

const MobileMenu = () => {
    const [menuIcon, setMenuIcon] = useState(false);
    return (
        <>
            <MobileMenuOffcanvas state={menuIcon} setState={setMenuIcon} />
            <div className="flex justify-between items-center">
                <div className="flex items-end">
                    <img src={logo} alt="" style={{ width: '30px' }} />
                    <h1
                        style={{
                            fontSize: '30px',
                            color: 'white',
                            fontWeight: '700',
                            lineHeight: '1.5rem',
                        }}
                    >
                        ixfar
                    </h1>
                </div>
                <label
                    htmlFor="mobileMenu"
                    className="cursur_pointer"
                    onClick={() => setMenuIcon(!menuIcon)}
                >
                    {menuIcon ? <X color="#fff" size={30} /> : <Menu color="#fff" size={30} />}
                </label>
            </div>
        </>
    );
};

export default MobileMenu;
