import React from 'react';
import { Link } from 'react-router-dom';
import useWindowWidth from '../../Utils/WindowCaptcher';
import Button from '../Button/Button';
import Container from '../Container/Container';
import Heading from '../Heading/Heading';
import PortfolioMobileSlider from './Mobile/PortfolioMobileSlider';
import PortfolioBox from './PortfolioBox/PortfolioBox';
import portfolioData from './PortfolioBox/protfolioData';

const PortfolioSection = ({ type }) => {
    const width1280 = useWindowWidth(1280);
    const width1024 = useWindowWidth(1024);
    const data = portfolioData;
    return (
      <div
        className="portfolio_section bg-[#000000c4]"
        style={{ padding: "100px 0" }}
      >
        <Container>
          <div className="flex justify-center pb-5">
            <Heading first="Our" middle="Projects" />
          </div>

          {width1024 ? (
            <PortfolioMobileSlider />
          ) : (
            <>
              <div
                className="mb-5 btn-group btn-group-vertical gap-1 lg:btn-group-horizontal "
                style={{ display: "none" }}
              >
                <Button className="btn-sm" link="/" text="all" />
                <Button className="btn-sm" link="/" text="Dasboard" />
                <Button className="btn-sm" link="/" text="Wordpress" />
                <Button className="btn-sm" link="/" text="ERP" />
                <Button className="btn-sm" link="/" text="Ecommerce" />
                <Button className="btn-sm" link="/" text="React JS" />
                <Button className="btn-sm" link="/" text="Wix" />
                <Button className="btn-sm" link="/" text="Shopify" />
                <Button className="btn-sm" link="/" text="Business" />
                <Button className="btn-sm" link="/" text="Others" />
              </div>

              <div
                className={`grid gap-7 justify-center ${
                  width1280 ? " grid-cols-3" : " grid-cols-4 "
                }`}
              >
                {width1280 ? (
                  <>
                    {data.map((portfolio) => (
                      <PortfolioBox
                        image={portfolio.thumb}
                        name={portfolio.title}
                        ratting="1"
                        link={portfolio.id}
                      />
                    ))}
                  </>
                ) : (
                  <>
                    {data.map((portfolio) => (
                      <PortfolioBox
                        image={portfolio.thumb}
                        name={portfolio.title}
                        ratting="1"
                        link={portfolio.id}
                      />
                    ))}
                  </>
                )}
              </div>
            </>
          )}

          <div className="text-center mt-10">
            {type === "page" ? '' : (
              <Link className="btn" to="/portfolio">View All Project</Link>
            )}
          </div>
        </Container>
      </div>
    );
};

export default PortfolioSection;
