import React from 'react';
import { CheckSquare } from 'react-feather';

const BestList = ({ text, color }) =>
    text && (
        <li className="mb-3">
            <div className="alert alert-dark border bg-[#000000] border-[#ffffff40] rounded-md block">
                <div>
                    <div
                        style={{
                            height: '50px',
                            width: '50px',
                            backgroundColor: color || '#008eff40',
                            padding: '10px',
                            borderRadius: '50%',
                        }}
                    >
                        <CheckSquare size={30} color="#fff" />
                    </div>
                    <h1 style={{ fontSize: '20px' }}>{text}</h1>
                </div>
            </div>
        </li>
    );

export default BestList;
