
import React, { useState } from "react";
import Container from '../Container/Container';
import Heading from '../Heading/Heading';
import ServiceBox from '../ServiceBox/ServiceBox';

import sectionData from "../../Pages/Service/ServiceData/sectionData";
import useWindowWidth from "../../Utils/WindowCaptcher";
import ServiceBody from "../ServiceBox/ServiceBody";
import Accordion from "./Services/BannerServiceBox/ServiceBoxMobile";

function ServiceSection() {
  const data = sectionData[0];
  const [active, setActive] = useState(data.title);
  const width1280 = useWindowWidth(1280);


  const [bodyData, setBodyData] = useState({
    bodyImg: data.image,
    desc: data.desc,
    link: "/erp",
    title: data.subtitle,
  });

  return (
    <>
      <div className="py-24 bgImage bg-[#191919]">
        <Container>
          <div className="flex justify-center pb-5">
            <Heading
              first="Our Best"
              middle="Software"
              middleRight="Services"
            />
          </div>

          {width1280 && sectionData.map((item, index) => {
            return (
              <div key={index}>
                <Accordion item={item} />
              </div>
            );
          })}

          {!width1280 && (
            <>
              <ServiceBody title={active} bodyData={bodyData} isButton />
              <div className="flex justify-center items-end -mt-12">
                {sectionData.map((item, index) => (
                  <div key={index}>
                    <ServiceBox
                      active={active}
                      setActive={setActive}
                      title={item.subtitle}
                      bodyImg={item.image}
                      desc={item.desc}
                      link={item.link}
                      setBodyData={setBodyData}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </Container>
      </div>
    </>
  );
}

export default ServiceSection
