/* eslint-disable import/no-unresolved */
import React from 'react';
// Import Swiper React components
import { Swiper } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, Navigation } from 'swiper';

const SwiperSlider = ({ children, type, speed }) => (
    <Swiper
        // pagination={
        //     type === 'banner' && {
        //         type: 'fraction',
        //     }
        // }
        style={{
            '--swiper-navigation-color': '#000',
            '--swiper-navigation-size': '25px',
        }}
        breakpoints={
            (type === 'slick' && {
                200: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                650: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 5,
                    spaceBetween: 10,
                },
                1023: {
                    slidesPerView: 6,
                    spaceBetween: 10,
                },
                1279: {
                    slidesPerView: 7,
                    spaceBetween: 10,
                },
            }) ||
            (type === 'testimonial' && {
                '@0.00': {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                '@0.75': {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
                '@1.00': {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                '@1.50': {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
            }) ||
            (type === 'service' && {
                767: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                // '@1.00': {
                //     slidesPerView: 3,
                //     spaceBetween: 20,
                // },
            }) ||
            (type === 'portfolio' && {
                550: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
            })
        }
        autoplay={{
            delay: speed,
            disableOnInteraction: false,
        }}
        navigation
        modules={[Autoplay, Navigation]}
        className="mySwiper"
    >
        {children}
    </Swiper>
);

export default SwiperSlider;
