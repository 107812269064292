import React from 'react';
import chooseBoxCss from '../choose.module.css';

const WhyChooseBox = ({ icon, title, desc }) => (
    <div className={`${chooseBoxCss.choose_box} border`}>
        <div className={chooseBoxCss.icon}>{icon}</div>
        <h1>{title}</h1>
        <p>{desc}</p>
    </div>
);

export default WhyChooseBox;
