import React from 'react';
import BackButton from '../../Components/Button/BackButton';
import Button from '../../Components/Button/Button';
import Container from '../../Components/Container/Container';
import LogoSlider from '../../Components/LogoSlider/LogoSlider';
import TestimonialSingel from '../../Components/Testimonial_Section/TestimonialSingel';
import Title from '../../Components/Title/Title';

const Review = () => {
    const avatar =
        'https://i.ibb.co/SvDTp0n/png-transparent-avatar-user-computer-icons-software-developer-avatar-child-face-heroes-thumbnail-rem.png';
    return (
        <>
            <div className="review_page py-32">
                <Container>
                    <Title
                        title="Our Client Say"
                        subTitle="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Incidunt harum dolorum sit assumenda architecto voluptatum expedita nam aliquam magni numquam?"
                    />

                    <div className="text-center mb-10">
                        <BackButton />
                    </div>

                    <div className="grid grid-cols-4 gap-5">
                        <TestimonialSingel
                            name="data.name"
                            img={avatar}
                            msg="doloremque ipsa mollitia placeat laboriosam earum. Iusto modi itaque unde sapiente dolorum? Dignissimosipisicing elit. Libero molestias amet consectetur adipisicing elit. Libero molestias deserunt necessitatibus maxime repellat reiciendis. Id  deserunt necessitatibus maxime repellat reiciendis.."
                            rtn="5"
                            time="1 day ago"
                            key="data._id"
                            className="border p-3 border-slate-600 rounded-lg"
                        />
                        <TestimonialSingel
                            name="data.name"
                            img={avatar}
                            msg="doloremque ipsa mollitia placeat laboriosam earum. Iusto modi itaque unde sapiente dolorum? Dignissimosipisicing elit. Libero molestias amet consectetur adipisicing elit. Libero molestias deserunt necessitatibus maxime repellat reiciendis. Id  deserunt necessitatibus maxime repellat reiciendis.."
                            rtn="5"
                            time="1 day ago"
                            key="data._id"
                            className="border p-3 border-slate-600 rounded-lg"
                        />
                        <TestimonialSingel
                            name="data.name"
                            img={avatar}
                            msg="doloremque ipsa mollitia placeat laboriosam earum. Iusto modi itaque unde sapiente dolorum? Dignissimosipisicing elit. Libero molestias amet consectetur adipisicing elit. Libero molestias deserunt necessitatibus maxime repellat reiciendis. Id  deserunt necessitatibus maxime repellat reiciendis.."
                            rtn="5"
                            time="1 day ago"
                            key="data._id"
                            className="border p-3 border-slate-600 rounded-lg"
                        />
                        <TestimonialSingel
                            name="data.name"
                            img={avatar}
                            msg="doloremque ipsa mollitia placeat laboriosam earum. Iusto modi itaque unde sapiente dolorum? Dignissimosipisicing elit. Libero molestias amet consectetur adipisicing elit. Libero molestias deserunt necessitatibus maxime repellat reiciendis. Id  deserunt necessitatibus maxime repellat reiciendis.."
                            rtn="5"
                            time="1 day ago"
                            key="data._id"
                            className="border p-3 border-slate-600 rounded-lg"
                        />
                        <TestimonialSingel
                            name="data.name"
                            img={avatar}
                            msg="doloremque ipsa mollitia placeat laboriosam earum. Iusto modi itaque unde sapiente dolorum? Dignissimosipisicing elit. Libero molestias amet consectetur adipisicing elit. Libero molestias deserunt necessitatibus maxime repellat reiciendis. Id  deserunt necessitatibus maxime repellat reiciendis.."
                            rtn="5"
                            time="1 day ago"
                            key="data._id"
                            className="border p-3 border-slate-600 rounded-lg"
                        />
                        <TestimonialSingel
                            name="data.name"
                            img={avatar}
                            msg="doloremque ipsa mollitia placeat laboriosam earum. Iusto modi itaque unde sapiente dolorum? Dignissimosipisicing elit. Libero molestias amet consectetur adipisicing elit. Libero molestias deserunt necessitatibus maxime repellat reiciendis. Id  deserunt necessitatibus maxime repellat reiciendis.."
                            rtn="5"
                            time="1 day ago"
                            key="data._id"
                            className="border p-3 border-slate-600 rounded-lg"
                        />
                        <TestimonialSingel
                            name="data.name"
                            img={avatar}
                            msg="doloremque ipsa mollitia placeat laboriosam earum. Iusto modi itaque unde sapiente dolorum? Dignissimosipisicing elit. Libero molestias amet consectetur adipisicing elit. Libero molestias deserunt necessitatibus maxime repellat reiciendis. Id  deserunt necessitatibus maxime repellat reiciendis.."
                            rtn="5"
                            time="1 day ago"
                            key="data._id"
                            className="border p-3 border-slate-600 rounded-lg"
                        />
                        <TestimonialSingel
                            name="data.name"
                            img={avatar}
                            msg="doloremque ipsa mollitia placeat laboriosam earum. Iusto modi itaque unde sapiente dolorum? Dignissimosipisicing elit. Libero molestias amet consectetur adipisicing elit. Libero molestias deserunt necessitatibus maxime repellat reiciendis. Id  deserunt necessitatibus maxime repellat reiciendis.."
                            rtn="5"
                            time="1 day ago"
                            key="data._id"
                            className="border p-3 border-slate-600 rounded-lg"
                        />
                        <TestimonialSingel
                            name="data.name"
                            img={avatar}
                            msg="doloremque ipsa mollitia placeat laboriosam earum. Iusto modi itaque unde sapiente dolorum? Dignissimosipisicing elit. Libero molestias amet consectetur adipisicing elit. Libero molestias deserunt necessitatibus maxime repellat reiciendis. Id  deserunt necessitatibus maxime repellat reiciendis.."
                            rtn="5"
                            time="1 day ago"
                            key="data._id"
                            className="border p-3 border-slate-600 rounded-lg"
                        />
                        <TestimonialSingel
                            name="data.name"
                            img={avatar}
                            msg="doloremque ipsa mollitia placeat laboriosam earum. Iusto modi itaque unde sapiente dolorum? Dignissimosipisicing elit. Libero molestias amet consectetur adipisicing elit. Libero molestias deserunt necessitatibus maxime repellat reiciendis. Id  deserunt necessitatibus maxime repellat reiciendis.."
                            rtn="5"
                            time="1 day ago"
                            key="data._id"
                            className="border p-3 border-slate-600 rounded-lg"
                        />
                        <TestimonialSingel
                            name="data.name"
                            img={avatar}
                            msg="doloremque ipsa mollitia placeat laboriosam earum. Iusto modi itaque unde sapiente dolorum? Dignissimosipisicing elit. Libero molestias amet consectetur adipisicing elit. Libero molestias deserunt necessitatibus maxime repellat reiciendis. Id  deserunt necessitatibus maxime repellat reiciendis.."
                            rtn="5"
                            time="1 day ago"
                            key="data._id"
                            className="border p-3 border-slate-600 rounded-lg"
                        />
                        <TestimonialSingel
                            name="data.name"
                            img={avatar}
                            msg="doloremque ipsa mollitia placeat laboriosam earum. Iusto modi itaque unde sapiente dolorum? Dignissimosipisicing elit. Libero molestias amet consectetur adipisicing elit. Libero molestias deserunt necessitatibus maxime repellat reiciendis. Id  deserunt necessitatibus maxime repellat reiciendis.."
                            rtn="5"
                            time="1 day ago"
                            key="data._id"
                            className="border p-3 border-slate-600 rounded-lg"
                        />
                    </div>
                    <div className="mt-10 text-center">
                        <Button type="button" text="Load More" anim />
                    </div>
                </Container>
            </div>
            <LogoSlider />
        </>
    );
};

export default Review;
