import React from 'react';
import logo from '../../Assets/Images/logo.png';
import useWindowWidth from '../../Utils/WindowCaptcher';
import Container from '../Container/Container';
import SocialIcon from '../SocialIcon/SocialIcon';
import FooterList from './FooterList';

const Footer = () => {
    const width1124 = useWindowWidth(1124);
    return (
      <div
        className={`footer !bg-[#000] ${width1124 ? "pt-0" : "pt-20"}  pb-5 `}
        
      >
        <Container>
          {width1124 ? (
            ""
          ) : (
            <div className="flex gap-20">
              <div className="footer_info" style={{ width: "40%" }}>
                <div className="flex items-end" style={{ gap: "5px" }}>
                  <img
                    src={logo}
                    alt="Pixfar"
                    style={{ width: "50px" }}
                    className="mb-5"
                  />
                  <h1
                    className="text-white"
                    style={{ fontSize: "50px", marginBottom: "30px" }}
                  >
                    ixfar
                  </h1>
                </div>
                <p
                  className="mb-5 text-[#ffffff90] text-[15px]"
                  style={{ lineHeight: "20px" }}
                >
                  Pixfar is a software company that specializes in ERP, POS, Big
                  Data, and Application Development solutions. We help
                  businesses of all sizes streamline their operations, improve
                  their efficiency, and make better decisions.
                </p>
                <SocialIcon />
              </div>
              <div style={{ width: "30%" }}>
                <h1 className="border-b pb-2 mb-5 text-xl border-blue-900 text-white">
                  Services
                </h1>
                <ul>
                  <FooterList link="/service/erp" text="ERP" />
                  <FooterList link="/service/lms" text="LMS" />
                  <FooterList link="/service/remote-desktop" text="RDP" />
                  <FooterList link="/service/data-engineering" text="Data Engineering" />
                  <FooterList
                    link="/service/web-development"
                    text="Web Development"
                  />
                  <FooterList link="/service/ai" text="AI Solutions" />
                </ul>
              </div>

              {/* <div style={{ width: "30%" }}>
                <h1 className="border-b pb-2 mb-5 text-xl border-blue-900 text-white">
                  Latest Blogs
                </h1>
                <ul>
                  <FooterList
                    link="/mobile-app-development"
                    text="Mobile App Development"
                  />
                  <FooterList link="/" text="Software Development" />
                  <FooterList link="/" text="Digital Marketing" />
                  <FooterList link="/" text="SEO" />
                  <FooterList link="/" text="SEO" />
                  <FooterList link="/" text="SEO" />
                </ul>
              </div> */}

              <div style={{ width: "30%" }}>
                <h1 className="border-b pb-2 mb-5 text-xl border-blue-900 text-white">
                  Address
                </h1>

                <img
                  src="https://i.ibb.co/8zC8ZBh/national-Flug.png"
                  alt=""
                  className="w-[35px] object-contain rounded"
                />
                <p className="text-[#ffffff60] text-[15px] ">
                  House #12, Road-08, Block-D <br /> Mirpur-11, Dhaka 1216
                  <br /> Bangladesh
                </p>

                <img
                  src="https://i.ibb.co/0rn9bV3/usa.png"
                  alt=""
                  className="w-[35px] object-contain rounded mt-2"
                />
                <p className="text-[#ffffff60] text-[15px]">
                  269 S La fayette park PL <br /> Los Angeles, California 90057
                  <br />
                  United State
                </p>

                <img
                  src="https://i.ibb.co/zP1gSjS/ndl.jpg"
                  alt=""
                  className="w-[35px] object-contain rounded mt-2"
                />
                <p className="text-[#ffffff60] text-[15px]">
                  Duivendrechtsekade 42 <br /> 1096 AH, Amsterdam
                  <br />
                  Netherlands
                </p>
              </div>
            </div>
          )}

          <p
            className={`mt-3 ${
              width1124 ? "" : "border-t"
            } w-full text-center pt-3 text-[15px] border-cyan-900 text-gray-400`}
          >
            Copyright © 2023 - All rights reserved by Pixfar Team
          </p>
        </Container>
      </div>
    );
};

export default Footer;
