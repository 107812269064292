const ProductPageData = [
    {
        slug: 'pix-gpt',
        bannerImage: '',
        title: 'Pix GPT',
        about: {
            title: 'Pix Meet',
            desc: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Optio sit necessitatibus in provident. Ea ex numquam earum vitae veritatis magni ipsum nisi blanditiis dicta. Sunt, iste quibusdam esse ipsam dolores quo, tempora, praesentium error quos ab libero eveniet asperiores quod fugit! Iste nihil illum ea error amet, optio voluptatem cupiditate.',
            user: '487',
            download: '500',
            view: '2',
            image: '',
        },
        apps: {
            image: '',
            title: 'Pix Meet Androaid',
            desc: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Optio sit necessitatibus in provident. Ea ex numquam earum vitae veritatis magni ipsum nisi blanditiis dicta. Sunt, iste quibusdam esse ipsam dolores quo, tempora, praesentium error quos ab libero eveniet asperiores quod fugit! Iste nihil illum ea error amet, optio voluptatem cupiditate.',
            downloadLink: '',
        },
        fetures: [
            {
                item: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore temporibus obcaecati itaque illum optio aperiam perspiciatis, amet beatae enim placeat veniam! Soluta nisi unde ipsa placeat, nulla earum,',
            },
            {
                item: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore temporibus obcaecati itaque illum optio aperiam perspiciatis, amet beatae enim placeat veniam! Soluta nisi unde ipsa placeat, nulla earum,',
            },
            {
                item: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore temporibus obcaecati itaque illum optio aperiam perspiciatis, amet beatae enim placeat veniam! Soluta nisi unde ipsa placeat, nulla earum,',
            },
            {
                item: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore temporibus obcaecati itaque illum optio aperiam perspiciatis, amet beatae enim placeat veniam! Soluta nisi unde ipsa placeat, nulla earum,',
            },
            {
                item: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore temporibus obcaecati itaque illum optio aperiam perspiciatis, amet beatae enim placeat veniam! Soluta nisi unde ipsa placeat, nulla earum,',
            },
            {
                item: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore temporibus obcaecati itaque illum optio aperiam perspiciatis, amet beatae enim placeat veniam! Soluta nisi unde ipsa placeat, nulla earum,',
            },
            {
                item: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore temporibus obcaecati itaque illum optio aperiam perspiciatis, amet beatae enim placeat veniam! Soluta nisi unde ipsa placeat, nulla earum,',
            },
        ],
    },
    {
        slug: 'pos',
        bannerImage: '',
        title: 'POS',
        about: {
            title: 'Pix Meet',
            desc: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Optio sit necessitatibus in provident. Ea ex numquam earum vitae veritatis magni ipsum nisi blanditiis dicta. Sunt, iste quibusdam esse ipsam dolores quo, tempora, praesentium error quos ab libero eveniet asperiores quod fugit! Iste nihil illum ea error amet, optio voluptatem cupiditate.',
            user: '487',
            download: '500',
            view: '2',
            image: '',
        },
        apps: {
            image: '',
            title: 'Pix Meet Androaid',
            desc: 'Lorem ipsum, dolor sit amet consectes in provident. Ea ex numquam earum vitae veritatis magni ipsum nisi blanditiis dicta. Sunt, iste quibusdam esse ipsam dolores quo, tempora, praesentium error quos ab libero eveniet asperiores quod fugit! Iste nihil illum ea error amet, optio voluptatem cupiditate.',
            downloadLink: '',
        },
        fetures: [
            {
                item: 'Lorem ipsum dolor sit amet conribus obcaecati itaque illum optio aperiam perspiciatis, amet beatae enim placeat veniam! Soluta nisi unde ipsa placeat, nulla earum,',
            },
            {
                item: 'it amet consectetur adipisicing elit. Tempore temporibus obcaecati itaque illum optio aperiam perspiciatis, amet beatae enim placeat veniam! Soluta nisi unde ipsa placeat, nulla earum,',
            },
            {
                item: 'Lorem ipsum doa placeat, nulla earum,',
            },
            {
                item: 'Lorem ipsum dolor sit amet consectetur adipisam perspiciatis, amet beatae enim placeat veniam! Soluta nisi unde ipsa placeat, nulla earum,',
            },
            {
                item: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore temporibus obcaecati itaque illum optio aperiam perspiciatis, amet beatae enim placeat veniam! Soluta nisi unde ipsa placeat, nulla earum,',
            },
            {
                item: 'Lorem ipsum dolor sit amet consectetur adie enim placeat veniam! Soluta nisi unde ipsa placeat, nulla earum,',
            },
            {
                item: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore temporibus obcaecati itaque illum optio aperiam placeat veniam! Soluta nisi unde ipsa placeat, nulla earum,',
            },
        ],
    },
    {
        slug: 'pix-meet',
        bannerImage: '',
        title: 'Pix Meet',
        about: {
            title: 'Pix Meet',
            desc: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Optio sit necessitatibus in provident. Ea ex numquam earum vitae veritatis magni ipsum nisi blanditiis dicta. Sunt, iste quibusdam esse ipsam dolores quo, tempora, praesentium error quos ab libero eveniet asperiores quod fugit! Iste nihil illum ea error amet, optio voluptatem cupiditate.',
            user: '487',
            download: '500',
            view: '2',
            image: '',
        },
        apps: {
            image: '',
            title: 'Pix Meet Androaid',
            desc: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Optio sit necessitatibus in provident. Ea ex numquam earum vitae veritatis magni ipsum nisi blanditiis dicta. Sunt, iste quibusdam esse ipsam dolores quo, tempora, praesentium error quos ab libero eveniet asperiores quod fugit! Iste nihil illum ea error amet, optio voluptatem cupiditate.',
            downloadLink: '',
        },
        fetures: [
            {
                item: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore temporibus obcaecati itaque illum optio aperiam perspiciatis, amet beatae enim placeat veniam! Soluta nisi unde ipsa placeat, nulla earum,',
            },
            {
                item: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore temporibus obcaecati itaque illum optio aperiam perspiciatis, amet beatae enim placeat veniam! Soluta nisi unde ipsa placeat, nulla earum,',
            },
            {
                item: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore temporibus obcaecati itaque illum optio aperiam perspiciatis, amet beatae enim placeat veniam! Soluta nisi unde ipsa placeat, nulla earum,',
            },
            {
                item: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore temporibus obcaecati itaque illum optio aperiam perspiciatis, amet beatae enim placeat veniam! Soluta nisi unde ipsa placeat, nulla earum,',
            },
            {
                item: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore temporibus obcaecati itaque illum optio aperiam perspiciatis, amet beatae enim placeat veniam! Soluta nisi unde ipsa placeat, nulla earum,',
            },
            {
                item: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore temporibus obcaecati itaque illum optio aperiam perspiciatis, amet beatae enim placeat veniam! Soluta nisi unde ipsa placeat, nulla earum,',
            },
            {
                item: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore temporibus obcaecati itaque illum optio aperiam perspiciatis, amet beatae enim placeat veniam! Soluta nisi unde ipsa placeat, nulla earum,',
            },
        ],
    },
    {
        slug: 'pix-tools',
        bannerImage: '',
        title: 'Pix Tools',
        about: {
            title: 'Pix Meet',
            desc: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Optio sit necessitatibus in provident. Ea ex numquam earum vitae veritatis magni ipsum nisi blanditiis dicta. Sunt, iste quibusdam esse ipsam dolores quo, tempora, praesentium error quos ab libero eveniet asperiores quod fugit! Iste nihil illum ea error amet, optio voluptatem cupiditate.',
            user: '487',
            download: '500',
            view: '2',
            image: '',
        },
        apps: {
            image: '',
            title: 'Pix Meet Androaid',
            desc: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Optio sit necessitatibus in provident. Ea ex numquam earum vitae veritatis magni ipsum nisi blanditiis dicta. Sunt, iste quibusdam esse ipsam dolores quo, tempora, praesentium error quos ab libero eveniet asperiores quod fugit! Iste nihil illum ea error amet, optio voluptatem cupiditate.',
            downloadLink: '',
        },
        fetures: [
            {
                item: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore temporibus obcaecati itaque illum optio aperiam perspiciatis, amet beatae enim placeat veniam! Soluta nisi unde ipsa placeat, nulla earum,',
            },
            {
                item: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore temporibus obcaecati itaque illum optio aperiam perspiciatis, amet beatae enim placeat veniam! Soluta nisi unde ipsa placeat, nulla earum,',
            },
            {
                item: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore temporibus obcaecati itaque illum optio aperiam perspiciatis, amet beatae enim placeat veniam! Soluta nisi unde ipsa placeat, nulla earum,',
            },
            {
                item: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore temporibus obcaecati itaque illum optio aperiam perspiciatis, amet beatae enim placeat veniam! Soluta nisi unde ipsa placeat, nulla earum,',
            },
            {
                item: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore temporibus obcaecati itaque illum optio aperiam perspiciatis, amet beatae enim placeat veniam! Soluta nisi unde ipsa placeat, nulla earum,',
            },
            {
                item: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore temporibus obcaecati itaque illum optio aperiam perspiciatis, amet beatae enim placeat veniam! Soluta nisi unde ipsa placeat, nulla earum,',
            },
            {
                item: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore temporibus obcaecati itaque illum optio aperiam perspiciatis, amet beatae enim placeat veniam! Soluta nisi unde ipsa placeat, nulla earum,',
            },
        ],
    },
];

export default ProductPageData;
