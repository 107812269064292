/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import { ArrowUp } from 'react-feather';

const BackToTop = () => {
    const [scrollToTop, setScrollToTop] = useState(false);
    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 400) {
                setScrollToTop(true);
            } else {
                setScrollToTop(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            {scrollToTop && (
                <button
                    type="button"
                    className="rounded-full"
                    style={{
                        position: 'fixed',
                        right: '10px',
                        bottom: '15px',
                        backgroundColor: '#008eff',
                        height: '65px',
                        width: '65px',
                        zIndex: '999999999999999999',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    onClick={handleScrollToTop}
                >
                    <ArrowUp size={30} color="#fff" />
                </button>
            )}
        </>
    );
};

export default BackToTop;
