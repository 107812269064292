const sectionData = [
  {
    title: "ERP Solutions",
    subtitle: "ERP Solutions",
    desc: "Transform your Core Business Processes with Strategic ERPNext Solutions.",
    image: "https://i.ibb.co/Xp7DWfM/erp-solutions-Cknk-Ei3c.jpg",
    icon: "",
    link: "/erp",
  },
  {
    title: "Data Engineering",
    subtitle: "Big Data Solutions",
    desc: "Data engineering is a crucial discipline within the field of data science that focuses on designing, constructing, and maintaining systems and architecture for collecting, storing, and analyzing large sets of data. The primary goal of data engineering is to ensure that data is accessible, reliable, and efficiently processed to support analytics and decision-making.",
    image: "https://i.ibb.co/R4mkzxT/pe-api-automation-Xe-WSdi2-J.png",
    icon: "",
    link: "/data-engineering",
  },
  {
    title: "AI Solutions",
    subtitle: "AI Solutions",
    desc: "We leverage Generative AI and Machine Learning to streamline processes, improve efficiencies, and reduce costs with our AI models. Our NLP techniques enhance customer interactions and improve operational efficiency.",
    image: "https://i.ibb.co/2W9NVfC/ai-Cx-Uhdp8m.png",
    icon: "",
    link: "/ai",
  },
  {
    title: "LMS Solutions",
    subtitle: "LMS Solutions",
    desc: "Revolutionize Education with Our Cutting-Edge Learning Management Solutions",
    image:
      "https://i.ibb.co/FhpTFzd/pe-application-modernization-Cs-Ko-Drqf.png",
    icon: "",
    link: "/lms",
  },
  {
    title: "Web Development",
    subtitle: "Web Development",
    desc: "Web development refers to the process of creating websites and web applications that are accessible over the internet. It involves several tasks, including web design, web content development, client-side/server-side scripting, and network security configuration, among others.",
    video: "software-consulting",
    image: "https://i.ibb.co/9qcT1yW/pe-cms-development-Bo-Wqg-BX4.png",
    icon: "",
    link: "/web-development",
  },
  {
    title: "RDP Solutions",
    subtitle: "Remote Desktop Solution",
    desc: "A Remote Desktop Solution (RDS) is a technology that allows users to access and interact with a computer or server from a remote location as if they were physically present at the machine. This is accomplished through a network connection, typically over the internet, enabling remote control of the desktop environment, applications, and files.",
    image: "https://i.ibb.co/cyj3Pwy/ba009f17334c055fb8ef1878cea5e710.jpg",
    icon: "",
    link: "/remote-desktop",
  },
];

export default sectionData;