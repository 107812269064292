import React from 'react';
import video from '../../Assets/videos/techvieo.mp4';
import useWindowWidth from '../../Utils/WindowCaptcher';
import Container from '../Container/Container';
import Heading from '../Heading/Heading';
import BestList from './BestList/BestList';

const BestForAbout = () => {
    const px1024 = useWindowWidth(1024);
    return (
      <div
        className="py-32"
        style={{
          backgroundImage: 'url("https://i.ibb.co/jrB5STZ/download-1.png")',
          backgroundPosition: "bottom",
          backgroundRepeat: "repeat",
          backgroundColor: "black",
        }}
      >
        <Container>
          <div className="flex justify-center pb-5">
            <Heading middle="What We Do Best" />
          </div>
          <div
            className={`${
              px1024 ? "block" : "grid"
            } grid-cols-2 gap-10 items-center`}
          >
            {px1024 && (
              //   <img
              //     src="https://i.ibb.co/GMWbstK/pexels-christina-morillo-1181263.jpg"
              //     alt=""
              //     className="m-auto w-3/4"
              //   />

              <video
                autoPlay
                muted
                loop
                playsInline
                className="w-full h-full object-cover"
              >
                <source src={video} type="video/mp4" />
              </video>
            )}
            <div className="list">
              <ul>
                <BestList
                  text="Enterprise Resource Planning (ERP)"
                  color="#008eff40"
                />
                <BestList
                  text="Learning Management System (LMS)"
                  color="#011627"
                />
                <BestList text="Data Engineering" color="#413020" />
                <BestList
                  text="Remote Desktop Solution (RDP)"
                  color="#60dbfa"
                />
                <BestList text="Artificial Intelligence (AI)" color="#214ce5" />
                <BestList text="Mobile App Development " color="#ce679a" />
                <BestList text="Software Consulting" color="#011627" />
              </ul>
            </div>

            {!px1024 && (
              <video
                autoPlay
                muted
                loop
                playsInline
                className="w-full h-full object-cover"
              >
                <source src={video} type="video/mp4" />
              </video>
            )}
          </div>
        </Container>
      </div>
    );
};

export default BestForAbout;
