import React from 'react';
import { Link } from 'react-router-dom';

const MenuList = ({ title, setState, link }) =>
    link && (
        <li>
            <Link to={link} onClick={() => setState(false)}>
                {title}
            </Link>
        </li>
    );

export default MenuList;
