import React from 'react';
import { Link } from 'react-router-dom';
import useWindowWidth from '../../../Utils/WindowCaptcher';
import portfolioCss from '../PortfolioSection.module.css';

// eslint-disable-next-line no-unused-vars
const PortfolioBox = ({ image, name, ratting, link }) => {
    const width1124 = useWindowWidth(1124);
    return (
      <div
        className={`card w-full bg-base-100 rounded-md overflow-hidden border border-[#191919] shadow-md hover:shadow-none  ${portfolioCss.portfolio}`}
      >
        <figure>
          <img
            src={
              image ||
              "https://i.pinimg.com/736x/60/38/e9/6038e9e53f35da8b730f9f757d032a3f.jpg"
            }
            alt="Shoes"
            className="border-b"
            style={{
              height: "250px",
              width: "100%",
              objectFit: "cover",
            }}
          />
        </figure>
        <div
          className={`card-body border-r-[red] bg-[#191919] ${
            width1124 ? "px-2 py-3" : "px-3 py-4"
          }`}
        >
          <h2 className="card-title text-lg text-white">{name || "N/A"}</h2>
          <div className="card-actions justify-start items-center gap-5">
            <Link className="btn" to={`/portfolio/${link}`}>
              View
            </Link>
          </div>
        </div>
      </div>
    );
};

export default PortfolioBox;
