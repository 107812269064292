import React from 'react';
import { SwiperSlide } from 'swiper/react';
import Container from '../Container/Container';
import SwiperSlider from '../SwiperSlide/SwiperSlide';
import Title from '../Title/Title';
import logoCss from './LogoSlider.module.css';

const LogoSlider = () => (
    <div className={logoCss.client_logo}>
        <Container>
            <div className={logoCss.logo}>
                <Title
                    title="Our Trusted Partner"
                    subTitle="Pixfar is your trusted partner for all your software needs. With our expertise, industry knowledge, and commitment to excellence, we strive to be your reliable ally on your journey to success. We prioritize understanding your unique requirements, goals, and challenges, ensuring that our solutions are tailor-made to meet your specific needs."
                />

                <SwiperSlider type="slick" speed={2000}>
                    <SwiperSlide>
                        <img
                            src="https://mcarthurridge.wpengine.com/wp-content/uploads/2023/05/Result-13-1-e1685334193864.webp"
                            alt=""
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src="https://developerachem.me/electic/wp-content/uploads/2023/03/logo-1.png"
                            alt=""
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src="https://www.revivetyres.co.uk/wp-content/uploads/2022/10/0235D20D-A6FD-4ECE-85FC-6D83D8F7DE45-2048x946.jpg"
                            alt=""
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src="https://i0.wp.com/custommadesupplier.com/wp-content/uploads/2022/11/1-copy-1.png?w=1666&ssl=1"
                            alt=""
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="https://i.ibb.co/C584Y7x/default-monochrome-white.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="https://i.ibb.co/kHkkFYT/Nijanand-Pharma-Logo-new.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="https://i.ibb.co/phLpKk9/Ecommerce-Logo.png" alt="" />
                    </SwiperSlide>
                </SwiperSlider>
            </div>
        </Container>
    </div>
);

export default LogoSlider;
