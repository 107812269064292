import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../Assets/Images/logo.png';
import useWindowWidth from '../../Utils/WindowCaptcher';
import Container from '../Container/Container';
import HeaderCss from './Header.module.css';
import MenuList from './MenuList/MenuList';
import MobileMenu from './Mobile/MobileMenu';

const Header = () => {
    const width1280 = useWindowWidth(1280);
    return (
      <>
        {/* <HeaderTop /> */}
        <div className={HeaderCss.header}>
          <Container>
            {width1280 ? (
              <MobileMenu />
            ) : (
              <div className={HeaderCss.header_body}>
                <div className={HeaderCss.logo}>
                  <Link to="/">
                    <img src={logo} alt="logo" />
                    <h1
                      style={{
                        fontSize: "40px",
                        fontWeight: "700",
                        position: "relative",
                        color: "#fff",
                      }}
                    >
                      ixfar
                    </h1>
                  </Link>
                </div>
                <div className={HeaderCss.menu}>
                  <MenuList />
                </div>
                <Link to="/contact-us" className="btn">
                  Get in Touch
                </Link>
              </div>
            )}
          </Container>
        </div>
      </>
    );
};

export default Header;
