import React from 'react';
import { Link } from 'react-router-dom';
import productBoxCss from '../products.module.css';

const ProductBox = ({ data }) => (
    <Link to={`/product/${data.slug}`} className={productBoxCss.box}>
        <img
            src="https://www.uctoday.com/wp-content/uploads/2022/06/How-to-Share-Screen-on-Google-Meet-1280x720.jpg"
            alt=""
        />
        <div className={productBoxCss.info}>
            <h1>{data.title}</h1>
        </div>
    </Link>
);

export default ProductBox;
