import React from 'react';
import { Home } from 'react-feather';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ link1, link2, link3, link4, current }) => (
    <ul className="flex gap-2 justify-center items-center">
        <li>
            <Link to="/" className="flex">
                <Home size={18} /> &nbsp;
            </Link>
        </li>
        {link1?.name && (
            <li>
                <Link to={link1?.link}>/&nbsp; {link1?.name}</Link>
            </li>
        )}
        {link2?.name && (
            <li>
                <Link to={link2?.link}>/&nbsp; {link2?.name}</Link>
            </li>
        )}
        {link3?.name && (
            <li>
                <Link to={link3?.link}>/&nbsp; {link3?.name}</Link>
            </li>
        )}
        {link4?.name && (
            <li>
                <Link to={link4?.link}>/&nbsp; {link4?.name}</Link>
            </li>
        )}
        {current && <li>/&nbsp; {current}</li>}
    </ul>
);

export default Breadcrumbs;
