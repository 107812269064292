/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Eye } from 'react-feather';
import { BiUserCircle } from 'react-icons/bi';
import { ImCloudDownload } from 'react-icons/im';
import { useParams } from 'react-router-dom';
import Button from '../../../Components/Button/Button';
import Container from '../../../Components/Container/Container';
import Newslatter from '../../../Components/Newslatter/Newslatter';
import PageTitle from '../../../Components/PageTitle/PageTitle';
import ScrollToTop from '../../../Components/ScrollToTop/ScrollToTop';
import useWindowWidth from '../../../Utils/WindowCaptcher';
import ProductPageData from '../productData/productData';

const ProductSingle = () => {
    const slug = useParams().name;
    const data = ProductPageData.filter((d) => d.slug === slug)[0];
    const px1024 = useWindowWidth(1024);
    const px500 = useWindowWidth(500);
    const px370 = useWindowWidth(370);

    return (
        <>
            <PageTitle
                current={data.title}
                link1={{ name: 'Product', link: '/products' }}
                title={data.title}
            />
            <ScrollToTop />
            <div style={{ backgroundColor: '#f5f8ff' }} className="py-20">
                <Container>
                    <div className={`${px1024 ? 'block' : 'grid'} grid-cols-2 gap-5`}>
                        {px1024 && (
                            <img
                                src={
                                    data?.about?.image ||
                                    'https://www.uctoday.com/wp-content/uploads/2022/06/How-to-Share-Screen-on-Google-Meet-1280x720.jpg'
                                }
                                alt=""
                                className="border rounded-md mb-5"
                            />
                        )}
                        <div>
                            <h1 className="mb-5 text-4xl font-bold " style={{ color: '#008eff' }}>
                                {data?.about?.title}
                            </h1>
                            <p className="mb-2">{data?.about?.desc}</p>
                            <div
                                className={`border ${
                                    px370 ? 'py-1 px-1' : px500 ? 'py-2 px-3' : 'py-3 px-5'
                                } rounded-md text-white inline-block mt-5`}
                                style={{ backgroundColor: '#008eff' }}
                            >
                                <div className="flex items-center">
                                    <div
                                        className={`border-r ${px500 ? 'pr-2 mr-2' : 'pr-5 mr-5'}`}
                                        style={{ fontSize: px370 ? '12px' : px500 && '14px' }}
                                    >
                                        Product Info
                                    </div>
                                    <div
                                        className={`flex items-center gap-2 border-r ${
                                            px500 ? 'pr-2 mr-2' : 'pr-5 mr-5'
                                        }`}
                                        style={{ fontSize: px370 ? '12px' : px500 && '14px' }}
                                    >
                                        <BiUserCircle size={px370 ? 15 : px500 ? 20 : 25} />
                                        {data?.about?.user}K
                                    </div>
                                    <div
                                        className={`flex items-center gap-2 border-r ${
                                            px500 ? 'pr-2 mr-2' : 'pr-5 mr-5'
                                        }`}
                                        style={{ fontSize: px370 ? '12px' : px500 && '14px' }}
                                    >
                                        <ImCloudDownload size={px370 ? 15 : px500 ? 20 : 25} />
                                        {data?.about?.download}K
                                    </div>
                                    <div
                                        className="flex items-center gap-2"
                                        style={{ fontSize: px370 ? '12px' : px500 && '14px' }}
                                    >
                                        <Eye size={px370 ? 15 : px500 ? 20 : 22} />
                                        {data?.about?.view}M
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!px1024 && (
                            <img
                                src={
                                    data?.about?.image ||
                                    'https://www.uctoday.com/wp-content/uploads/2022/06/How-to-Share-Screen-on-Google-Meet-1280x720.jpg'
                                }
                                alt=""
                                className="border rounded-md"
                            />
                        )}
                    </div>
                </Container>
            </div>

            <div style={{ backgroundColor: '#f5f8ff' }} className="py-20">
                <Container>
                    <div className={`${px1024 ? 'block' : 'grid'} grid-cols-2 gap-5`}>
                        <img
                            src={
                                data?.apps?.image ||
                                'https://androidcommunity.com/wp-content/uploads/2020/05/Google-Meet-for-Android-Background-Blur.jpg'
                            }
                            alt=""
                            className="border rounded-md mb-5"
                        />
                        <div>
                            <h1
                                className={`mb-5 ${px500 ? 'text-2xl' : 'text-4xl'} font-bold`}
                                style={{ color: '#008eff' }}
                            >
                                {data?.apps?.title}
                            </h1>
                            <p className="mb-2">{data?.apps?.desc}</p>
                            <div className="mt-5 flex gap-5">
                                <Button
                                    link={data?.apps?.downloadLink || '/'}
                                    text="Download Apps"
                                />
                            </div>
                        </div>
                    </div>
                </Container>
            </div>

            <div style={{ backgroundColor: '#f5f8ff' }} className="py-20">
                <Container>
                    <h1
                        className={`mb-5 ${px500 ? 'text-2xl' : 'text-4xl'} font-bold`}
                        style={{ color: '#008eff' }}
                    >
                        Fetures
                    </h1>

                    <div
                        className="service_single mb-10"
                        style={{ borderLeft: '5px solid #008eff', paddingLeft: '30px' }}
                    >
                        <ul>
                            {data?.fetures.map((item, index) => (
                                <li className="mb-3" key={index}>
                                    {item.item}
                                </li>
                            ))}
                        </ul>
                    </div>

                    <Button id="offcanvas" text="Request a Quote" />
                </Container>
            </div>
            <Newslatter />
        </>
    );
};

export default ProductSingle;
