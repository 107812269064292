/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import Container from '../../Components/Container/Container';
import Newslatter from '../../Components/Newslatter/Newslatter';
import PageTitle from '../../Components/PageTitle/PageTitle';
import Testimonial from '../../Components/Testimonial_Section/Testimonial';
import Title from '../../Components/Title/Title';
import useWindowWidth from '../../Utils/WindowCaptcher';
import ProductBox from './productBox/ProductBox';
import ProductPageData from './productData/productData';

const Products = () => {
    const px1280 = useWindowWidth(1280);
    const px1024 = useWindowWidth(1024);
    const px620 = useWindowWidth(620);
    return (
        <>
            <PageTitle title="Our Products" current="Products" />
            <div style={{ backgroundColor: '#f5f8ff' }} className="py-20">
                <Container>
                    <Title title="All Products" />
                    <div
                        className={`grid ${
                            px620
                                ? 'grid-cols-1'
                                : px1024
                                ? 'grid-cols-2'
                                : px1280
                                ? 'grid-cols-3'
                                : 'grid-cols-4'
                        } gap-5`}
                    >
                        {ProductPageData.map((d, index) => (
                            <ProductBox key={index} data={d} />
                        ))}
                    </div>
                </Container>
            </div>
            <Newslatter />
            <Testimonial />
        </>
    );
};

export default Products;
