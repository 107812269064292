import React, { useState } from 'react';
import inputCss from '../formElement.module.css';

const Textarea = ({ id, label, icon, className }) => {
    const [textarea, setTextarea] = useState('');
    return (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label className={inputCss.label_textarea}>
        <p className="text-[#ffffffa9] !-mb-4 mt-4 text-[15px]">{label}</p>
        <textarea
          id={id}
          onKeyUp={(e) => setTextarea(e.target.value)}
          className={`input input-bordered !bg-[#202020] w-full ${className}`}
          rows="3"
        />
      </label>
    );
};

export default Textarea;
